import React from "react";

import { XCircleIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faSatellite } from "@fortawesome/free-solid-svg-icons";

let instructionArrayList = [
  {
    instructionText: (
      <>
        1. Click the location pin marker 📍 to select a point/location in the
        map. <br />
        You may also use the geolocation tool to determine your current location.
      </>
    ),
    iconName: faMapMarkedAlt,
  },
  {
    instructionText:
      "2. Input the minimum elevation of the satellite (default is set to 40°).",
    iconName: faWrench,
  },
  {
    instructionText:
      "3. Click the GO button. Wait for few minutes to display the results/",
    iconName: faArrowAltCircleRight,
  },
  // {
  //   instructionText:
  //     "4. Availability means at least one satellite is visible from the observer.",
  //   iconName: faSatellite,
  // },
];

let availabilityArray = [
  {
    instructionText:
      "Simply counting the # of satellites that pass overhead in a given area at certain min elevation",
    iconName: faWrench,
    data: [],
  },
  {
    instructionText:
      "Necessary condition for establishing a connection, but not sufficient",
    iconName: faArrowAltCircleRight,
    data: [],
  },
  {
    instructionText:
      "Actual connection, its quality and speed, depends on other factors, such as, among others:",
    iconName: faArrowAltCircleRight,
    data: ["Satellite and ground network downtime", "Availability of the Internet gateway (GW)", "Weather conditions"],
  },
];

export default function LeoModal(props) {
  return (
    <>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-1600 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-xl font-semibold">
                  About LEO tracker Web App
                </h3>
                <button
                  className="h-6 w-6 cursor-pointer rounded-full"
                  onClick={props.setModalVisibility}
                >
                  <XCircleIcon className="text-gray-500" />
                </button>
              </div>
              <p className="text-base text-gray-600 text-justify px-6">
                <br />
                Low Earth Observation (LEO) tracker is an integrated web app in
                PhilSA space dashboard. <br />
                It displays useful parameters of a satellite constellation(s)
                that provides internet and determine its <br />
                availability in the a location instance plotted by the user in
                the map.
              </p>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                {instructionArrayList.map((item, index) => (
                  <div className="flex flex-row flex-wrap" key={index}>
                    <div className="inline-flex items-center">
                      <FontAwesomeIcon
                        fixedWidth
                        icon={item.iconName}
                        className="text-lg text-gray-800 mx-3"
                      />
                      <p className="text-gray-500 text-base py-1">
                        {item.instructionText}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              
              
              <div className="relative p-6 flex-auto">
                <div className="flex flex-col text-xl"> Availability</div>
                <ul className="relative list-disc p-6 flex-auto">
                  {availabilityArray.map((item, index) => (
                    <li>
                      <div className="flex flex-row flex-wrap" key={index}>
                        <div className="inline-flex items-center">
                          <p className="text-gray-500 text-base py-1">
                            {item.instructionText}
                          </p>
                        </div>
                      </div>
                      {item.data.length > 0 && 
                        <div className="flex">
                          <div className="flex-none w-10"></div>
                          <div className="grow">
                          <ul className="list-disc p-2 flex-auto">
                            {item.data.map((item2, index) => ( 
                              <li>
                                <div className="flex flex-row flex-wrap" key={index}>
                                  <div className="inline-flex items-center">
                                    <p className="text-gray-500 text-base py-1">
                                      {item2}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          </div>
                        </div>                      
                      }                      
                    </li>
                  ))}
                </ul>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={props.setModalVisibility}
                >
                  Okay, got it!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-1550 bg-black"></div>
      </>
    </>
  );
}
