import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import AuthContextProvider from "./contexts/AuthContext";

import { ProjectView } from "./pages/ProjectMapview";
// import { NotFoundPage } from "./pages/NoMatch";

const App = (props) => {
  // store.dispatch(loadUser());
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <BrowserRouter>
          <Switch>
            <Route
              path={`/${process.env.REACT_APP_ROUTE_COVID}`}
              component={ProjectView}
              route={process.env.REACT_APP_ROUTE_COVID}
            />
            {/* <Route path="*">
              <NotFoundPage />
            </Route> */}
          </Switch>
        </BrowserRouter>
      </AuthContextProvider>
    </Provider>
  );
};

export default App;
