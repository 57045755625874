import React from "react";
import { Switch } from "@headlessui/react";

export default function ToggleSwitch(props) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={props.isCheck}
          onChange={props.onToggleSwitch}
          className={props.activeClassname}
        >
          <span className={props.switchKnobClassname} />
        </Switch>
        <Switch.Label className="ml-4">{props.switchLabel}</Switch.Label>
      </div>
    </Switch.Group>
  );
}
