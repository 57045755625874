import L from "leaflet";

import NatGov from "../../assets/media/nat_gov.svg";
import LGU from "../../assets/media/lgu.svg";
import Academe from "../../assets/media/academe.svg";
import NGO from "../../assets/media/ngo.svg";
import Private from "../../assets/media/private.svg";

import { PedroPopup, DatosPopup, S4SPopup } from ".";

export const getPopup = (projectID) => {
  switch (projectID) {
    case "pedro":
      return PedroPopup;
    case "datos":
      return DatosPopup;
    case "stamina_stakeholder":
      return S4SPopup;
    default:
      return PedroPopup;
  }
};

export const getTitle = (projectID) => {
  switch (projectID) {
    case "covid":
      return "Covid Data";
    case "pedro":
      return "PEDRO Data";
    case "datos":
      return "DATOS Data";
    case "novasar":
      return "SAR with AIS Data";
    default:
      return "Covid Data";
  }
};

const markerSize = new L.Point(25, 30);
export const NatGovIcon = new L.Icon({
  iconUrl: NatGov,
  iconSize: markerSize,
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  maxWidth: "auto",
});
export const LGUIcon = new L.Icon({
  iconUrl: LGU,
  iconSize: markerSize,
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  maxWidth: "auto",
});
export const AcademeIcon = new L.Icon({
  iconUrl: Academe,
  iconSize: markerSize,
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  maxWidth: "auto",
});
export const NGOIcon = new L.Icon({
  iconUrl: NGO,
  iconSize: markerSize,
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  maxWidth: "auto",
});
export const PrivateIcon = new L.Icon({
  iconUrl: Private,
  iconSize: markerSize,
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  maxWidth: "auto",
});

export const iconCreateFunction = (cluster) => {
  var markers = cluster.getAllChildMarkers();
  var weight = 0;
  markers.forEach((marker) => {
    weight += marker.options.options.customWeight;
  });

  var c = " marker-cluster-";
  if (weight < 10) {
    c += "small";
  } else if (weight < 100) {
    c += "medium";
  } else {
    c += "large";
  }
  // create the icon with the "weight" count, instead of marker count
  return L.divIcon({
    html: "<div><span>" + weight + "</span></div>",
    className: "marker-cluster" + c,
    iconSize: new L.Point(40, 40),
  });
};
