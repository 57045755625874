import React from "react";

import { XCircleIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVirus } from "@fortawesome/free-solid-svg-icons";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faSatellite } from "@fortawesome/free-solid-svg-icons";

let instructionArrayList = [
  {
    instructionText: "1. Click preferred project logo on the left.",
    iconName: faVirus,
  },
  {
    instructionText:
      "2. Relevant research and development outputs will show depending on the selected project.",
    iconName: faListUl,
  },
  {
    instructionText: "3. Turn on or off slider to show data for each theme.",
    iconName: faToggleOn,
  },
  {
    instructionText: "4. Click marker or any pin.",
    iconName: faMapMarkerAlt,
  },
  {
    instructionText: "5. Wait for the images and information to load.",
    iconName: faImage,
  },
  {
    instructionText:
      "6. Hide or unhide drawer using the close icon on the upper left.",
    iconName: faTimesCircle,
  },
  {
    instructionText: "7. To display map layers, click slider on the overlays.",
    iconName: faLayerGroup,
  },

];

export default function InstructionModal(props) {
  return (
    <>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-1600 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-xl font-semibold">How to use</h3>
                <button
                  className="h-6 w-6 cursor-pointer rounded-full"
                  onClick={props.setModalVisibility}
                >
                  <XCircleIcon className="text-gray-500" />
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                {instructionArrayList.map((item, index) => (
                  <div className="flex flex-row flex-wrap" key={index}>
                    <div className="inline-flex items-center">
                      <FontAwesomeIcon
                        fixedWidth
                        icon={item.iconName}
                        className="text-lg text-gray-800 mx-3"
                      />
                      <p className="text-gray-500 text-base py-1">
                        {item.instructionText}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={props.setModalVisibility}
                >
                  Okay, got it!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-1550 bg-black"></div>
      </>
    </>
  );
}
