import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import Moment from "react-moment";
import moment from "moment";

import Tippy from "@tippyjs/react";

import ActiveSatIco from "../../assets/media/sat-pass.svg";

const CardForm = (props) => {
  console.log(props.activeSatellites)

  const active_satellites = props.constellation=="oneweb"?props.activeSatellites.oneweb.active_satellites:props.activeSatellites.starlink.active_satellites
  const updated_date = moment(props.constellation=="oneweb"?props.activeSatellites.oneweb.updated_date:props.activeSatellites.starlink.updated_date)
  // const updated_date = '1976-04-19T12:59-0500'

  return (
    <div className="px-4 m-2 py-3 max-w-xxs rounded overflow-hidden shadow-md bg-gray-100">
      <form>
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-base text-gray-700">
              Satellite Constellation
            </h3>
            <button
              className="rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={props.onClickCollapse}
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faChevronCircleRight}
                className="text-base text-gray-300 mx-1"
              />
            </button>
          </div>
          <div className="pt-4 pb-2">
            <button
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 
             ${
               props.constellation === "oneweb"
                 ? "bg-green-500 text-white"
                 : "bg-gray-200 text-gray-700"
             }`}
              type="button"
              onClick={props.onSelectOneWeb}
            >
              OneWeb
            </button>
            <button
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 
              ${
                props.constellation === "starlink"
                  ? "bg-green-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              type="button"
              onClick={props.onSelectStarlink}
            >
              Starlink
            </button>
            {/* available satellites tab */}
            <div className="flex flex-row w-full items-center py-2">
              {/* <div className="flex-grow-0 flex-shrink-0 self-stretch w- text-left block">
                <img className="w-9" src={ActiveSatIco} alt={props.altLabel} />
              </div> */}
              <div className="flex-auto self-stretch w-auto text-left block pl-3">
                <div className="text-sm p-0">Active Satellites</div>
                <div className="text-base text-gray-700 p-0 -mt-1.5 text-lg">
                  {active_satellites}
                </div>
                <div className="text-base text-gray-400 p-0 -mt-1.5">
                  <p className="text-xxs">as of <Moment format="MMM DD, yyyy">{updated_date}</Moment><Moment format=" hh:mm:ss a">{updated_date}</Moment></p>
                </div>

              </div>
            </div>
            {/* #################### */}
          </div>          
          <div className="flex flex-wrap">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-gray-700">
                Min. Elevation
              </h3>
            </div>
            <p className="text-xs text-gray-400">
              Indicate inclination of satellite
            </p>
          </div>
          <div className="flex flex-row my-3">
            <div className="flex-nowrap flex-grow">
              <input
                className="max-w-nano p-2 rounded-l-md border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white focus: outline-none focus:shadow-sm"
                type="number"
                value={props.inclination}
                aria-label="Inclination degree"
                onChange={props.onChangeInput}
              />
              <button
                className="py-2 px-3 rounded-r-lg bg-blue-400 text-gray-700 font-bold uppercase"
                type="submit"
                onClick={props.onHandleSubmit}
              >
                GO
              </button>
            </div>
          </div>
          <Tippy content={<span>Coordinates (LAT LON)</span>} placement="right">
            <div className="flex my-1 items-center">
              <div className="flex-nowrap flex-grow">
                <div className="text-gray-500 bg-gray-300 text-sm rounded leading-loose font-semibold py-1">
                  <FontAwesomeIcon
                    fixedWidth
                    icon={faGlobe}
                    className="text-lg text-gray-800 ml-2 mr-3"
                    onClick={props.onClickAction2}
                  />
                  <span className="mr-4">{props.latValue}</span>{" "}
                  <span>{props.lonValue}</span>
                </div>
              </div>
            </div>
          </Tippy>
        </div>
      </form>
    </div>
  );
};

export default CardForm;
