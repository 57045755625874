import React from "react";

import CardDetails from "./CardDetails";

import ActiveSatIco from "../../assets/media/sat-pass.svg";
import TotalSatPassIco from "../../assets/media/total-sat.svg";
import UniqueSatCountIco from "../../assets/media/unique-sat.svg";
import UptimeIco from "../../assets/media/uptime.svg";
import DowntimeIco from "../../assets/media/downtime.svg";

const CardStats = (props) => {
  return (
    <div className="px-4 m-2 py-3 max-w-xxs rounded overflow-hidden shadow-md bg-gray-100">
      <form>
        <div className="flex">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-base text-gray-700">
              Stats and Figures
            </h3>
          </div>
        </div>
        <p className="text-xs text-gray-400">Query results</p>
        <div className="my-3">
          {/* <CardDetails
            iconAsset={ActiveSatIco}
            statLabel="Active satellites"
            statDetail={props.figure1}
          /> */}
          <CardDetails
            iconAsset={TotalSatPassIco}
            statLabel="Satellite pass count"
            statDetail={props.figure2}
          />
          <CardDetails
            iconAsset={UniqueSatCountIco}
            statLabel="Unique satellites"
            statDetail={props.figure3}
          />
          <CardDetails
            iconAsset={UptimeIco}
            statLabel="Availability percentage"
            statDetail={props.figure4}
          />
          <CardDetails
            iconAsset={DowntimeIco}
            statLabel="Period with 0 satellites"
            statDetail={props.figure5}
          />
        </div>
      </form>
    </div>
  );
};

export default CardStats;
