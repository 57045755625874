import React from "react";
import Tippy from "@tippyjs/react";

const NavbarItem = (props) => {
  const {
    navBarItemAltName,
    navBarIcon,
    navBarIconAlt,
    navBarFunction,
    tooltipName,
  } = props;

  return (
    <div className="flex items-center">
      <Tippy content={<span>{tooltipName}</span>} placement="right">
        <button className="navbar-btn" onClick={navBarFunction}>
          <span className="sr-only">{navBarItemAltName}</span>
          <img src={navBarIcon} alt={navBarIconAlt} className="h-8 w-8" />
        </button>
      </Tippy>
    </div>
  );
};

export default NavbarItem;
