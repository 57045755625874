import React, { createContext, useReducer } from "react";
import { authContextReducer } from "../reducers/authContextReducer";

export const AuthContext = createContext();

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: false,

  errorMessage: "",
  hasErrorMessage: false,

  forPasswordReset: false,

  is_admin: false,
  is_staff: false,
  user_id: 0,
  email: "",
};

const AuthContextProvider = (props) => {
  const [auth, dispatch] = useReducer(authContextReducer, initialState);
  return (
    <AuthContext.Provider value={{ auth, AuthDispatch: dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
