import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { passDataToDrawer } from "../../actions/http_request";
import { XCircleIcon } from "@heroicons/react/solid";
import { FilterIcon, DownloadIcon, TrendingUpIcon, TrendingDownIcon } from '@heroicons/react/solid'
import Modal from './modal.js'
import './constant.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


// INTERNS IMPORT
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { generateData, options, downloadCSV } from "./operaChart";
// import { data, generateData, options } from "../../constants (temp)/air_quality_chart_constants";
import 'chartjs-adapter-moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// REGISTER CHART COMPONENTS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const GIFComponent = ({drawerMeasurement}) => (
  <div>
    <img
      src={drawerMeasurement.measurements[0].image}
      className="max-w-full max-h-96 my-2"
      alt="chevron drawer"
    />
  </div>
)

const ChartComponent = ({drawerData, drawerMeasurement, drawerVisible, startDate, endDate}) => (
  <div style={{width: '100%'}}>
    {/* Chart */}
    <div className="mb-2 bg-white border-style">
      <Line 
          data={generateData(drawerMeasurement, drawerVisible, startDate, endDate).graph_data} 
          // options={options}
          options={{
            ...options,
            scales: {
              y: {
                title:{
                    display: true,
                    text: '\u03BCmol / m\u00B2',      
                    // text: drawerMeasurement.id == 4? '\u03BCmol / m\u00B2':'DU', 
                    // text: 'umol/m<sup>2</sup>',      
                    color: 'black'      
                },
                ticks: {
                    display: true,
                    color: 'rgba(0,0,0,1)'
                },
                grid: {
                    display: true,
                    color: 'rgba(0,0,0,0.3)'
                },
              },
              x: {
                title:{
                    display: false,
                    text: 'Date',
                    color: 'black'
                },
                ticks: {
                    display: true,
                    maxRotation: 45,
                    minRotation: 45,
                    color: 'rgba(0,0,0,1)'
                },
                grid: {
                    display: true,
                    color: 'rgba(0,0,0,0.35)'
                },
                type: 'time',
                time: {
                    unit: 'month',
                    displayFormats: {
                        month: 'MMM'
                    }
                }
              }
            }
          }}
          height={200}
      />
    </div>
    {/* Filter button */}
    <div className="flex flex-row">
      {/* <div className="mb-4">
        <button className="flex flex-row items-center justify-between ease-in filter-button" onClick={() => modalPopper()}>
          <div className="h-5 w-5 mt-0.1"><FilterIcon className="filter-icon"/></div>
          FILTER
        </button>
        <Modal open={isOpen} setStartDate={callbackSetStartDate} setEndDate={callbackSetEndDate}/>
      </div> */}

      {/* Download CSV Button */}
      <div className="mb-4">
        <button className="flex flex-row items-center justify-between ease-in filter-button" onClick={() => downloadCSV(drawerMeasurement, drawerData.name)}>
          <div className="h-5 w-5"><DownloadIcon className="h-5 w-5"/></div>
          DOWNLOAD CSV
        </button>
      </div>
    </div>

    

    {/* Variables */}
    {/* <div className="flex flex-row justify-between mb-3"> 
      {variablesID}
    </div>
    <div className="flex flex-row justify-between mb-5 gap-10">
      {generateLatest}
    </div>
    <div className="flex flex-row justify-between mb-10 gap-10">
      {generateAverage}
    </div> */}
  </div>
)

const VisualizationComponent = ({variables, tabSelected, setTabSelected}) => (
  <Fragment>
    <div class={`text-sm font-medium text-center text-gray-500 ${variables.length > 1 && "border-b"} border-gray-200 dark:text-gray-400 dark:border-gray-700`}>
        <ul class="flex flex-wrap -mb-px">
            {
              variables.length > 1 && variables.map((variable, index)=>(
                <li class="mr-2" onClick={()=>setTabSelected(index)}>
                  {index === tabSelected && <a class="inline-block p-4 text-gray-900 border-b-2 border-gray-500 rounded-t-lg active dark:text-gray-500 dark:border-gray-500">{variable.name}</a>}
                  {index !== tabSelected && <a class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">{variable.name}</a>}
                </li>
              ))
            }
        </ul>
    </div>
  </Fragment>
)

const Covid19DataOutputs = (props) => {
  const drawerData = props.drawerDataAll;
  const drawerMeasurement = props.drawerMeasurementAll;
  const drawerVisible = props.drawerVisible;
  const drawerLoader = props.drawerLoader;

  console.log(drawerMeasurement)
  console.log(drawerMeasurement.variables)

  // TEMPORARY COMPONENTS
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [fixed, setFixed] = useState(generateData(drawerMeasurement))
  const [chartData, setChartData] = useState();
  
  // tab
  const [tabSelected, setTabSelected] = useState(0);

  useEffect(()=>{
    setTabSelected(0)
  }, [props.drawerMeasurementAll])

  const callbackSetStartDate = (date) => {
    setStartDate(date);
    // setChartData()
  }

  const callbackSetEndDate = (date) => {
    setEndDate(date);
  }

  const modalPopper = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  
  
  return (
    <div className="flex-grow-0 flex-shrink-0 w-1/3 p-3">
      <div
        className={`ease-out transition-all ${
          props.drawerVisible &&
          "z-1500 shadow-md w-2/5 ease-in absolute overflow-visible top-0 right-0 left-auto flex flex-col h-full flex-grow flex-shrink-0 overflow-y-auto px-7 drawer-bg"
        }`}
      >
        {
          drawerLoader && 
          <div className="flex flex-col h-full drawer-margin">
            <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="5x"
                className="text-green-500"
              />
              {/* <i className="fas fa-circle-notch fa-spin fa-5x"></i> */}
            </span>
          </div>
        }

        {
          !drawerLoader &&
          (<div className="flex flex-col h-full drawer-margin">
            <div className="justify-start">
              <div className="absolute top-2 left-3">
                <button
                  className="h-6 w-6 cursor-pointer rounded-full"
                  onClick={() => {
                    props.passDataToDrawer("opera", {}, false);
                  }}
                >
                  <XCircleIcon className="text-gray-500" />
                </button>
              </div>
              
              {
                "variables" in drawerMeasurement &&
                <Fragment>
                  <h3 className="text-lg font-semibold text-center text-gray-800 mt-5 mb-5">
                    {drawerData.name.replace("NO2", "NO\u2082").replace("SO2", "SO\u2082")}
                  </h3>
    
                  {
                    "variables" in drawerMeasurement &&
                    <VisualizationComponent 
                      variables = {drawerMeasurement.variables}
                      tabSelected = {tabSelected}
                      setTabSelected = {(val)=>setTabSelected(val)}
                    />
                  }

                  { "variables" in drawerMeasurement &&
                      drawerMeasurement.variables.filter((val,index)=>index===tabSelected).map((variable, index) => (
                        <div className="flex items-center justify-center pt-3">
                          {
                            variable.id == 8 && 
                            <GIFComponent
                              drawerMeasurement={variable}
                            />
                          }
                          {
                            variable.id != 8 && 
                            <ChartComponent 
                              drawerData={drawerData}
                              drawerMeasurement={variable}
                              drawerVisible={drawerVisible}
                              startDate={startDate}
                              endDate={endDate}
                            />
                          }
                        </div>
                      ))
                  }
                  

                  {/* { "variables" in drawerMeasurement &&
                  <Carousel showArrows={true} dynamicHeight={true}>
                    { drawerMeasurement.variables.map((variable) => (
                      <div className="flex items-center justify-center">
                        {
                          variable.id == 8 && 
                          <GIFComponent
                            drawerMeasurement={variable}
                          />
                        }
                        {
                          variable.id != 8 && 
                          <ChartComponent 
                            drawerData={drawerData}
                            drawerMeasurement={variable}
                            drawerVisible={drawerVisible}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        }
                      </div>
                    ))
                    }
                  </Carousel>
                  } */}
                  
                  

                  <h2 className="text-base font-semibold text-gray-400 margin-5">
                    {drawerData.data_source}
                  </h2>

                  {/* LAND COVER DRAWER CONTENT */}
                  {drawerData.legend ? (
                    <div>
                      <h3 className="text-base font-semibold text-gray-400 mb-3">
                        Legend
                      </h3>
                      <div className="flex flex-wrap border-2 border-green-400 p-4 mb-4">
                        {drawerData.legend.map((value, index) => {
                          return (
                            <div
                              className="flex flex-row items-center"
                              key={`legend_${index}`}
                            >
                              <div
                                style={{ background: value.color }}
                                className="h-4 w-4 rounded-full border border-gray-800"
                              ></div>{" "}
                              <span className="mx-4">{value.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <p className="text-justify mt-3 mb-10 text-gray-600">
                    {drawerData.description.replace("NO2", "NO\u2082").replace("SO2", "SO\u2082")}
                  </p>

                </Fragment>
              }

              
            </div>
          </div>)
        }
        
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  drawerDataAll: state.mapReducer.drawerData,
  drawerMeasurementAll: state.mapReducer.drawerMeasurement,
  drawerVisible: state.mapReducer.drawerVisible,
  drawerLoader: state.mapReducer.drawerLoader,
});

export default connect(mapStateToProps, { passDataToDrawer })(
  withRouter(Covid19DataOutputs)
);
