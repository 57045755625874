import React, { Fragment, useState } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Moment from "react-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import UptimeBarChart from "../cards/UptimeBarChart";
import DowntimeTable from "../cards/DowntimeTable";

const Slider = (props) => {
  const [isPanelOpen, setPanelState] = useState(true);

  // console.log(props.leoData);

  console.log(isPanelOpen);
  return (
    <Fragment>
      <div className="absolute right-full top-3">
        {isPanelOpen && (
          <button
            className="rounded outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              setPanelState(false);
            }}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={faChevronCircleRight}
              className="text-base text-gray-400"
            />
          </button>
        )}
        {!isPanelOpen && (
          <button
            className="rounded outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              setPanelState(true);
            }}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={faChevronCircleLeft}
              className="text-base text-gray-400"
            />
          </button>
        )}
      </div>
      {isPanelOpen && (
        <div
          className={`side-panel max-w-sm ${
            isPanelOpen && "transform-gpu translate-x-0"
          }`}
          // className="side-panel max-w-sm"
        >
          <div className="flex">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="font-semibold text-base text-gray-700 text-left">
                24 hour "Availability" graph
              </h3>
              <h5>
                <Moment format="MMM DD, yyyy">{props.leoData.start}</Moment> -
                &nbsp;
                <Moment format="MMM DD, yyyy">{props.leoData.end}</Moment>
              </h5>
            </div>
          </div>
          <div className="my-3">
            <UptimeBarChart />
            <DowntimeTable />
          </div>
        </div>
      )}
    </Fragment>
  );
};

// export default Slider;

const chartStateToProps = (state) => ({
  leoData: state.mapReducer.leoData,
});

export default connect(chartStateToProps, {})(withRouter(Slider));
