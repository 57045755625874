import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  getLeoData,
  toggleSelectPoint,
  setPointSelected,
  passDataToDrawer,
  // getCurrentLocation,
  getActiveSatellites, // get active satellites for
} from "../../actions/http_request";

import { connect } from "react-redux";

import Control from "react-leaflet-control";
import MapToolButtonGroup from "../../components/buttons/MapToolButtonGroup";
import CircularButton from "../../components/buttons/CircularButton";
import CardForm from "../../components/cards/CardForm";
import CardStats from "../../components/cards/CardStats";

import Slider from "../../components/common/Slider";
import LeoModal from "../../components/modals/LeoHelp";
import {
  Marker,
  GeoJSON,
  Circle,
  Popup,
  TileLayer,
  WMSTileLayer,
} from "react-leaflet";
import L from "leaflet";

import { Markup, Interweave } from "interweave";
import { UrlMatcher } from "interweave-autolink";

import { faSatellite, faQuestion } from "@fortawesome/free-solid-svg-icons";

import SatIcon from "../../assets/media/sat.svg";
import GatewayIcon from "../../assets/media/satellite-dish-flat.png";

const satMarker = new L.Icon({
  iconUrl: SatIcon,
  iconSize: new L.Point(25, 30),
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  style: "transform: rotate(80deg);",
});

const gatewayMarker = new L.Icon({
  iconUrl: GatewayIcon,
  iconSize: new L.Point(25, 30),
  iconAnchor: [10, 28],
  popupAnchor: [3, -20],
  style: "transform: rotate(80deg);",
});

const SatelliteInternetAvailability = (props) => {
  const [angle, setAngle] = useState(40);
  const [constellation, setConstellation] = useState("oneweb");
  const [open, setOpen] = useState(true);
  const [modalState, setModalState] = useState(false);

  const onPointToolClick = () => props.toggleSelectPoint();
  // const onGeolocationToolClick = () => getCurrentLocation({ AuthDispatch });
  const onGeolocationToolClick = () => {
    console.log("geolocation");
    navigator.geolocation.getCurrentPosition(
      (e) => {
        props.setPointSelected({
          pointSelected: [e.coords.latitude, e.coords.longitude],
        });
      },
      () => alert("Please enable location services in your browser or phone")
    );
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log("submit");
    if (props.hasPointSelected)
      props.getLeoData({
        params: {
          angle,
          lat: props.pointSelected[0],
          lon: props.pointSelected[1],
          constellation,
        },
      });
    else if (props.hasGeolocationPlotted)
      props.getLeoData({
        params: {
          angle,
          lat: props.plottedGeolocation[0],
          lon: props.plottedGeolocation[1],
          constellation,
        },
      });
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " sec") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  // trigger matomo
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      var _paq = (window._paq = window._paq || []);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      (function () {
        var u = "https://pedro.asti.dost.gov.ph/analytics/";
        _paq.push(["setTrackerUrl", u + "matomo.php"]);
        _paq.push(["setSiteId", "5"]);

        var d = document,
          g = d.createElement("script"),
          s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
      })();
    }
    // eslint-disable-next-line
  }, []);

  // get activce satellites
  useEffect(() => {
    if (!props.hasActiveSatellites) {
      props.getActiveSatellites();
    }
    // eslint-disable-next-line
  }, []);

  if (!props.hasActiveSatellites) return <></>;

  console.log(props.activeSatellites);

  //   props.activeSatellites.starlink.gateways.features.forEach(feature=>{
  //     console.log([feature.geometry.coordinates[1], feature.geometry.coordinates[0]])
  //  })

  return (
    <Fragment>
      {props.hasPointSelected && (
        <Marker position={props.pointSelected}></Marker>
      )}
      <Control position="topleft" className="font-sans">
        <MapToolButtonGroup
          onClickAction1={onPointToolClick}
          onClickAction2={onGeolocationToolClick}
        />
        <CircularButton
          faIcon={faSatellite}
          tooltipName={!open ? "Show form" : "Hide form"}
          onClickAction={() => {
            // console.log("test", open);
            setOpen(!open);
          }}
        />
        {open && (
          <Fragment>
            <CardForm
              onClickCollapse={() => {
                setOpen(false);
              }}
              onHandleSubmit={submitForm}
              onSelectStarlink={() => setConstellation("starlink")}
              onSelectOneWeb={() => setConstellation("oneweb")}
              inclination={angle}
              onChangeInput={(e) => setAngle(e.target.value)}
              latValue={
                props.hasPointSelected
                  ? props.pointSelected[0].toFixed(4)
                  : "No value"
              }
              lonValue={
                props.hasPointSelected
                  ? props.pointSelected[1].toFixed(4)
                  : "No value"
              }
              constellation={constellation}
              setConstellation={setConstellation}
              activeSatellites={props.activeSatellites}
            />
            {props.gettingLeoData && console.log("fetching data")}
            {props.hasLeoData && (
              <CardStats
                figure1={props.leoData.total_starlink_count}
                figure2={props.leoData.total_sat}
                figure3={props.leoData.unique_sat}
                figure4={props.leoData.uptime.toFixed(2) + "%"}
                figure5={secondsToHms(props.leoData.downtime.toFixed(2))}
              />
            )}
            <CircularButton
              faIcon={faQuestion}
              tooltipName={"Help"}
              onClickAction={() => {
                // console.log("test", open);
                setModalState(true);
              }}
            />
          </Fragment>
        )}
      </Control>
      {props.hasLeoData && (
        <Control position="topright" className="font-sans">
          <Slider />
        </Control>
      )}
      {props.hasLeoData && (
        <Fragment>
          {props.leoData.current_sat.map((c) => (
            <Fragment>
              <Marker icon={satMarker} position={c.position}>
                <Popup>
                  <div>Sat Name: {c.sat_name}</div>
                  <div>Direction: {c.direction}</div>
                </Popup>
              </Marker>
              <GeoJSON data={c.geojson} />
            </Fragment>
          ))}
        </Fragment>
      )}
      {modalState ? (
        <LeoModal setModalVisibility={() => setModalState(false)} />
      ) : null}

      {/* <GeoJSON data={props.activeSatellites.starlink.gateways} /> */}
      {constellation == "starlink" &&
        props.activeSatellites.starlink.gateways.features.map((feature) => (
          //  <GeoJSON data={feature} />
          <Fragment>
            <Marker
              icon={gatewayMarker}
              position={[
                feature.geometry.coordinates[1],
                feature.geometry.coordinates[0],
              ]}
            >
              <Popup>
                <div className="font-semibold h1">
                  {feature.properties.Name}
                </div>
                <br />
                <Interweave
                  content={feature.properties.description}
                  matchers={[new UrlMatcher("url")]}
                />
              </Popup>
            </Marker>

            {feature.properties.has_service && (
              <Circle
                center={[
                  feature.geometry.coordinates[1],
                  feature.geometry.coordinates[0],
                ]}
                radius={941000}
                fill={false}
                color="#ffc738"
                weight="2"
              />
            )}
          </Fragment>
        ))}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  leoData: state.mapReducer.leoData,
  gettingLeoData: state.mapReducer.gettingLeoData,
  hasLeoData: state.mapReducer.hasLeoData,
  selectedPointToggle: state.mapReducer.selectedPointToggle,
  isVisible: state.mapReducer.isVisible,
  hasPointSelected: state.mapReducer.hasPointSelected,
  pointSelected: state.mapReducer.pointSelected,
  setPointSelected: state.mapReducer.setPointSelected,
  geolocationToggle: state.mapReducer.geolocationToggle,
  hasGeolocationPlotted: state.mapReducer.hasGeolocationPlotted,
  plottedGeolocation: state.mapReducer.plottedGeolocation,
  drawerVisible: state.mapReducer.drawerVisible,
  activeSatellites: state.mapReducer.activeSatellites,
  hasActiveSatellites: state.mapReducer.hasActiveSatellites,
});

export default connect(mapStateToProps, {
  getLeoData,
  toggleSelectPoint,
  setPointSelected,
  passDataToDrawer,
  getActiveSatellites,
})(withRouter(SatelliteInternetAvailability));

// const currentPage = pro+=-098s.location.pathname;
