import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { Marker, Popup, Polygon, WMSTileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

// reducer imports
import { connect } from "react-redux";
import {
  getProjectAll,
  toggleControl,
  passDataToDrawer,
  showWelcomeDrawer,
} from "../../actions/http_request";

import {
  NatGovIcon,
  iconCreateFunction,
  getPopup,
} from "../../components/popup/markers";

var overlayList = []; // list of overlay id's to show;

// function parseOverlayResults(item){
//   item['legend'] = JSON.parse(item['legend']);
//   return item
// }
const ProjectDetailAll = (props) => {
  // const [total, setTotal] = useState(0);
  const [overlays, setOverlays] = useState([]);
  const [visible, setVisible] = useState(false);
  const [project, setProject] = useState("opera");

  useEffect(() => {
    if ("project_id" in props.match.params) {
      props.getProjectAll(props.match.params.project_id);
      setProject(props.match.params.project_id);
      overlayList = [];
      if (props.match.params.project_id === "opera") {
        props.showWelcomeDrawer(true);
        // let url = `${process.env.REACT_APP_API_URL}opera/`;
        let url = `${process.env.REACT_APP_API_URL}covidmapoverlay/`;
        axios({
          method: "get",
          url: url,
        })
          .then((resp) => {
            console.log("loaded");
            // Transform legend to JSON object
            console.log(resp.data);
            setOverlays(resp.data);
          })
          .catch((err) => {
            console.log(err.response);
            console.log(err);
          });
      } else {
        setOverlays([]);
        setVisible(false);
      }
    } else {
      props.getProjectAll();
    }
  }, [props.match.params]);

  // cleanup function to hide drawer
  useEffect(() => {
    return () => {
      props.passDataToDrawer("opera", {}, false);
    };
    // eslint-disable-next-line
  }, []);

  const getDataPopup = (source, data) => {
    const DataPopup = getPopup(source);
    return <DataPopup data={data} />;
  };

  // const handleDrawer = (data) => {
  //   setVisible(true)
  //   setDrawerData(data.target.options.value)
  // };

  // important!!
  if (!props.hasOperaData) return <></>;

  return (
    <Fragment>
      {props.hasSummary && props.hasControl && (
        <div className="ml-3 mt-3 float-left clear-both relative pointer-events-auto z-1500 bg-gray-100 p-4 shadow-lg rounded">
          <div className="table text-base">
            <div className="table-header-group text-base font-semibold">
              Data
            </div>
            <div className="table-row-group">
              {props.operaDetailAllTags.map((d, i) => (
                <div className="table-row" key={`summary_${i}`}>
                  <div className="table-cell">
                    <ToggleSwitch
                      switchLabel={project === "opera" ? d.name : d.label}
                      isChecked={d.checked}
                      onToggleSwitch={() => {
                        props.toggleControl(
                          project === "opera" ? d.dataset_id : d.tag
                        );
                        props.passDataToDrawer(project, {}, false);
                      }}
                      activeClassname={`${
                        d.checked === true ? "bg-green-600" : "bg-gray-200"
                      } toggle-active`}
                      switchKnobClassname={`${
                        d.checked === true ? "translate-x-5" : "translate-x-0"
                      } toggle-inactive`}
                    />
                  </div>
                  <div className="table-cell pl-3">{d.value}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="table text-base">
            <div className="table-header-group text-base font-semibold">
              Overlays
            </div>
            <div className="table-row-group">
              {overlays.map((layer, i) => (
                <div className="table-row" key={`overlay_${i}`}>
                  <div className="table-cell">
                    <ToggleSwitch
                      switchLabel={layer.name}
                      isChecked={visible}
                      onToggleSwitch={() => {
                        setVisible(!visible);
                        if (!visible) {
                          overlayList.push(layer.id);
                          props.passDataToDrawer(
                            project,
                            {
                              name: layer.name,
                              description: layer.description,
                              legend: layer.legend,
                            },
                            true
                          );
                          props.showWelcomeDrawer(false);
                        } else {
                          overlayList = overlayList.filter(
                            (x) => x !== layer.id
                          );
                          props.passDataToDrawer(project, {}, false);
                        }
                      }}
                      activeClassname={`${
                        visible === true ? "bg-green-600" : "bg-gray-200"
                      } toggle-active`}
                      switchKnobClassname={`${
                        visible === true ? "translate-x-5" : "translate-x-0"
                      } toggle-inactive`}
                    />
                  </div>
                  <div className="table-cell pl-3">
                    <button
                      onClick={() => {
                        props.passDataToDrawer(
                          project,
                          {
                            name: layer.name,
                            description: layer.description,
                            legend: layer.legend,
                          },
                          true
                        );
                        props.showWelcomeDrawer(false);
                      }}
                    >
                      <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* For land cover */}
      {/* {overlays.length > 0 && project === "opera" && (
        <div className="table text-base">
          <div className="table-header-group text-base font-semibold">
            Overlays
          </div>
          <div className="table-row-group">
            {overlays.map((layer, i) => (
              <div className="table-row" key={`overlay_${i}`}>
                <div className="table-cell">
                  <ToggleSwitch
                    switchLabel={layer.name}
                    isChecked={visible}
                    onToggleSwitch={() => {
                      setVisible(!visible);
                      if (!visible) {
                        overlayList.push(layer.id);
                        props.passDataToDrawer(
                          project,
                          {
                            name: layer.name,
                            description: layer.description,
                            legend: layer.legend,
                          },
                          true
                        );
                        props.showWelcomeDrawer(false);
                      } else {
                        overlayList = overlayList.filter(
                          (x) => x !== layer.id
                        );
                        props.passDataToDrawer(project, {}, false);
                      }
                    }}
                    activeClassname={`${
                      visible === true ? "bg-green-600" : "bg-gray-200"
                    } toggle-active`}
                    switchKnobClassname={`${
                      visible === true ? "translate-x-5" : "translate-x-0"
                    } toggle-inactive`}
                  />
                </div>
                <div className="table-cell pl-3">
                  <button
                    onClick={() => {
                      props.passDataToDrawer(
                        project,
                        {
                          name: layer.name,
                          description: layer.description,
                          legend: layer.legend,
                        },
                        true
                      );
                      props.showWelcomeDrawer(false);
                    }}
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )} */}

      {overlays.length > 0 && visible && (
        <WMSTileLayer
          key={`tilelayer_landcover`}
          url={`${process.env.REACT_APP_GEOSERVER_URL}`}
          layers="landcover-ai-s4s:landcover-ai-s4s-all"
          tileSize={256}
          srs="EPSG:4326"
          transparent={true}
          tiled={true}
          format="image/png"
        />
      )}

      {/* {overlays.length > 0 &&
          overlays
            .filter((data) => overlayList.includes(data.location_id))
            .map((layer, i) => (
              <WMSTileLayer
                key={`tilelayer_${i}`}
                url={`${process.env.REACT_APP_GEOSERVER_URL}`}
                layers="landcover-ai-s4s:landcover-ai-s4s-all"
                tileSize={256}
                srs="EPSG:4326"
                transparent={true}
                tiled={true}
                format="image/png"
              />
              // <TileLayer
              //   key={`tilelayer_${i}`}
              //   url={layer.layer_url}
              //   minZoom={layer.layer_min_zoom}
              //   maxZoom="14"
              //   format="png"
              //   time=""
              //   tilematrixset="GoogleMapsCompatible_Level"
              //   opacity="0.90"
              //   tms={layer.is_tms}
              // />
            ))} */}

      <MarkerClusterGroup
        iconCreateFunction={iconCreateFunction}
        maxClusterRadius={37}
      >
        {props.operaDetailAllTags
          .filter((t) =>
            project === "opera"
              ? typeof t.name !== "undefined"
              : t.type === "marker"
          )
          .map((t, i) => {
            return (
              t.checked &&
              props.operaData.marker[project === "opera" ? t.name : t.tag].map(
                (data, index) => (
                  <Marker
                    key={`search_result_${index}`}
                    position={
                      project === "opera"
                        ? [
                            data.location_id.coords[0][1],
                            data.location_id.coords[0][0],
                          ]
                        : [data.lat, data.lon]
                    }
                    icon={NatGovIcon}
                    options={
                      project === "opera"
                        ? { customWeight: 1 }
                        : { customWeight: data.weight }
                    }
                    onclick={() => {
                      project === "opera"
                        ? props.passDataToDrawer(project, data, true)
                        : props.passDataToDrawer(project, data, true);
                      props.showWelcomeDrawer(false);
                    }}
                  >
                    {project === "opera" ? (
                      <Popup className="font-sans">
                        <h3 className="font-semibold text-base">
                          {data.name
                            .replace("NO2", "NO\u2082")
                            .replace("SO2", "SO\u2082")}
                        </h3>
                      </Popup>
                    ) : (
                      <Popup>{getDataPopup(data.source, data)}</Popup>
                    )}
                  </Marker>
                )
              )
            );
          })}
      </MarkerClusterGroup>

      {props.operaDetailAllTags
        .filter((t) => t.type === "polygon")
        .map((t, i) => {
          return (
            t.checked &&
            props.operaData.polygon[t.tag].map((data, index) => (
              <Polygon
                key={`multipolygon_${index}`}
                color="lightslategrey"
                fill={false}
                weight="1"
                positions={data.geojson.coordinates[0]}
              ></Polygon>
            ))
          );
        })}

      {/* <img src={Logos} className="map-logos"></img> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  operaData: state.mapReducer.operaData,
  operaDetailAllTags: state.mapReducer.operaDetailAllTags,
  operaTitle: state.mapReducer.operaTitle,
  operaSummary: state.mapReducer.operaSummary,
  hasSummary: state.mapReducer.hasSummary,
  hasControl: state.mapReducer.hasControl,
  hasOperaData: state.mapReducer.hasOperaData,
});

export default connect(mapStateToProps, {
  getProjectAll,
  toggleControl,
  passDataToDrawer,
  showWelcomeDrawer,
})(withRouter(ProjectDetailAll));
