import React, { Fragment } from "react";
import { Bar } from "react-chartjs-2";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const UptimeBarChart = (props) => {
  const barOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            offsetGridLines: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Time (24h)",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Percentage availability (%) ",
          },
        },
      ],
    },
  };

  const barData = {
    labels: props.leoData.minute_breakdown.map((m) => m.index),
    datasets: [
      {
        label: "AVAILABILITY",
        backgroundColor: "#2BF0E7",
        data: props.leoData.minute_breakdown.map((m) => m.uptime.toFixed(4)),
      },
    ],
  };

  return (
    <Fragment>
      <Bar data={barData} options={barOptions} />
    </Fragment>
  );
};

const chartStateToProps = (state) => ({
  leoData: state.mapReducer.leoData,
});

export default connect(chartStateToProps, {})(withRouter(UptimeBarChart));
