import {
  CHANGE_MAP_PARAMS,
  GETTING_FLOOD_GEOJSON_LIST,
  STORE_FLOOD_GEOJSON_LIST,
  GETTING_FLOOD_LAYER_TITLES,
  STORE_FLOOD_LAYER_TITLES,
  GETTING_FLOOD_FEATURE_COLLECTION,
  STORE_FLOOD_FEATURE_COLLECTION,
  GETTING_LEO_DATA,
  SET_LEO_DATA,
  SET_SELECTED_POINT_TOGGLE,
  SET_POINT_SELECTED,
  SET_GEOLOCATION_BUTTON_TOGGLE,
  SET_PLOTTED_GEOLOCATION,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_DRAWER_LOADER,
  HIDE_DRAWER_LOADER,
  GETTING_DISASTER_POINTS,
  SET_DISASTER_POINTS,
  GETTING_ODK_DRAWER_DATA,
  SET_ODK_DRAWER_DATA,
  SET_ODK_PROJECT,
  CLEAN_ODK_DRAWER,
  SHOW_PICTURE_MODAL,
  HIDE_PICTURE_MODAL,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  agencies: [],
  hasAgencies: false,

  requests: [],
  hasRequests: false,

  projects: [],
  hasProjects: false,

  projectData: [],
  hasProjectData: false,

  projectDatosData: {
    points: [],
  },
  hasProjectDatosData: false,

  projectPreginetData: {
    nren: [],
    telemedicine: [],
  },
  hasProjectPreginetData: false,

  projectNovasarData: [],
  hasProjectNovasarData: false,

  projectOptimizationData: {
    optimization_station: [],
    optimization_request: [],
  },
  hasProjectOptimizationData: false,

  operaData: {
    data: {
      marker: {},
      polygon: {},
    },
  },
  operaTitle: "",
  operaDetailAllTags: [],
  operaSummary: [],
  hasOperaData: false,
  hasControl: false,
  hasSummary: false,

  hasLoader: false,

  drawerLoader: false,
  drawerData: {},
  drawerMeasurement: {},
  drawerVisible: false,
  drawerWelcome: true,
  // projectMultipleData: {},

  floodGeojsonList: [],
  gettingFloodGeojsonList: false,
  hasFloodGeojsonList: false,

  floodLayerTitles: [],
  gettingFloodLayerTitles: false,
  hasFloodLayerTitles: false,

  floodFeatureCollection: {},
  gettingFloodFeatureCollection: false,
  hasFloodFeatureCollection: false,

  // LEO
  leoData: null,
  gettingLeoData: false,
  hasLeoData: false,

  selectedPointToggle: false,
  isVisible: true,

  hasPointSelected: false,
  pointSelected: [14, 121],

  hasActiveSatellites: false,
  activeSatellites: {},

  // geolocation
  geolocationToggle: false,
  hasGeolocationPlotted: false,
  plottedGeolocation: null,
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour

  // disaster report
  odkProject: "pinas_network",

  disasterPoints: [],
  gettingDisasterPoints: false,
  hasDisasterPoints: false,

  showODKDrawer: false,
  odkDrawerData: {},
  gettingODKDrawerData: false,
  hasODKDrawerData: false,

  pictureModalUrl: "",
  showPictureModal: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_MAP_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case GETTING_FLOOD_GEOJSON_LIST:
      return {
        ...state,
        gettingFloodGeojsonList: true,
        hasFloodGeojsonList: false,
      };
    case STORE_FLOOD_GEOJSON_LIST:
      return {
        ...state,
        floodGeojsonList: action.payload.floodGeojsonList,
        gettingFloodGeojsonList: false,
        hasFloodGeojsonList: true,
      };
    case GETTING_FLOOD_LAYER_TITLES:
      return {
        ...state,
        gettingFloodLayerTitles: true,
        hasFloodLayerTitles: false,
      };
    case STORE_FLOOD_LAYER_TITLES:
      return {
        ...state,
        floodLayerTitles: action.payload.floodLayerTitles,
        gettingFloodLayerTitles: false,
        hasFloodLayerTitles: true,
      };
    case GETTING_FLOOD_FEATURE_COLLECTION:
      return {
        ...state,
        gettingFloodFeatureCollection: true,
        hasFloodFeatureCollection: false,
      };
    case STORE_FLOOD_FEATURE_COLLECTION:
      return {
        ...state,
        floodFeatureCollection: action.payload.floodFeatureCollection,
        gettingFloodFeatureCollection: false,
        hasFloodFeatureCollection: true,
      };
    case GETTING_LEO_DATA:
      return {
        ...state,
        gettingLeoData: true,
        hasLeoData: false,
      };
    case SET_LEO_DATA:
      return {
        ...state,
        leoData: action.payload.leoData,
        gettingLeoData: false,
        hasLeoData: true,
      };
    case SET_SELECTED_POINT_TOGGLE:
      console.log("SET_SELECTED_POINT_TOGGLE");
      return {
        ...state,
        selectedPointToggle: true,
        hasPointSelected: false,
        pointSelected: null,
      };
    case SET_POINT_SELECTED:
      console.log("SET_POINT_SELECTED");
      console.log(action.payload.pointSelected);
      return {
        ...state,
        selectedPointToggle: false,
        hasPointSelected: true,
        pointSelected: action.payload.pointSelected,
      };
    case SET_GEOLOCATION_BUTTON_TOGGLE:
      return {
        ...state,
        geolocationButtonToggle: true,
        hasGeolocationPlotted: false,
        plottedGeolocation: null,
      };
    case SET_PLOTTED_GEOLOCATION:
      return {
        ...state,
        geolocationButtonToggle: false,
        hasGeolocationPlotted: true,
        plottedGeolocation: action.payload.plottedGeolocation,
      };
    case SHOW_LOADER:
      return {
        ...state,
        hasLoader: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        hasLoader: false,
      };
    case SHOW_DRAWER_LOADER:
      return {
        ...state,
        drawerLoader: true,
      };
    case HIDE_DRAWER_LOADER:
      return {
        ...state,
        drawerLoader: false,
      };
    case GETTING_DISASTER_POINTS:
      return {
        ...state,
        hasLoader: true,
        showODKDrawer: false,
        gettingDisasterPoints: true,
        hasDisasterPoints: false,
      };
    case SET_DISASTER_POINTS:
      return {
        ...state,
        hasLoader: false,
        gettingDisasterPoints: false,
        hasDisasterPoints: true,
        disasterPoints: action.payload.disasterPoints,
      };
    case GETTING_ODK_DRAWER_DATA:
      return {
        ...state,
        showODKDrawer: true,
        gettingODKDrawerData: true,
        hasODKDrawerData: false,
      };
    case SET_ODK_DRAWER_DATA:
      return {
        ...state,
        showODKDrawer: true,
        gettingODKDrawerData: false,
        hasODKDrawerData: true,
        odkDrawerData: action.payload.odkDrawerData,
      };
    case SET_ODK_PROJECT:
      return {
        ...state,
        odkProject: action.payload.odkProject,
      };
    case CLEAN_ODK_DRAWER:
      return {
        ...state,
        showODKDrawer: false,
        gettingDisasterPoints: false,
        hasDisasterPoints: false,
        gettingODKDrawerData: false,
        hasODKDrawerData: false,
        odkProject: "",
      };
    case SHOW_PICTURE_MODAL:
      return {
        ...state,
        showPictureModal: true,
        pictureModalUrl: action.payload.pictureModalUrl,
      };
    case HIDE_PICTURE_MODAL:
      return {
        ...state,
        showPictureModal: false,
        pictureModalUrl: "",
      };
    default:
      return state;
  }
}
