import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
    getDisasterPoints,
    getPointDetails,
    setODKProject,
    cleanDrawer,
} from "../../actions/http_request";

import { connect } from "react-redux";

import Control from "react-leaflet-control";
import { Marker, GeoJSON, Circle, Popup, TileLayer, WMSTileLayer, } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

const DisasterIncidence = (props) => {

  const odkProject = "disaster_incidence";

  useEffect(() => {
    props.setODKProject(odkProject)

    console.log('disaster report', props.hasDisasterPoints)
    if(!props.hasDisasterPoints){
      console.log('get disaster points')
      props.getDisasterPoints(odkProject)
    }

    return () => {
      console.log('disaster cleanup')
      props.cleanDrawer()
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log('get disaster points')
    props.getDisasterPoints(odkProject)
    // eslint-disable-next-line
  }, [props.odkProject]);


  return (
    <Fragment>
      <Control position="topleft" className="font-sans">
      </Control>

      <MarkerClusterGroup
        // iconCreateFunction={iconCreateFunction}
        maxClusterRadius={37}
      >
      { props.hasDisasterPoints && 
        props.disasterPoints.map((point, index)=>
          <Marker
            key={`disaster_list_${index}`}
            position={point.position}
            onClick={()=>{
              props.getPointDetails(point, odkProject)
            }}
          >

          </Marker>
          )
      }</MarkerClusterGroup>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  disasterPoints: state.mapReducer.disasterPoints,
  gettingDisasterPoints: state.mapReducer.gettingDisasterPoints,
  hasDisasterPoints: state.mapReducer.hasDisasterPoints,
});

export default connect(mapStateToProps, {
    getDisasterPoints,
    getPointDetails,
    setODKProject,
    cleanDrawer,
})(withRouter(DisasterIncidence));
