import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeODKDrawer, showPictureModal } from "../../actions/http_request";
import { XCircleIcon } from "@heroicons/react/solid";

import Moment from "react-moment";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const getDrawerTitle = (odkProject) => {
  if (odkProject === "pinas_network") return "Land Cover Fieldwork";
  if (odkProject === "water_fieldwork") return "Water Fieldwork";
  if (odkProject === "disaster_incidence") return "Disaster Incidence";
  if (odkProject === "agricultural_survey") return "Agricultural Survey";

  return "Land Use Classification";
};

const ODKDrawer = (props) => {
  console.log(props.odkProject);

  const landcover_column = [
    {
      label: "Area Name",
      variable: "area_name",
    },
    {
      label: "Landcover Classification",
      variable: "land_cover_classification",
    },
    {
      label: "Landcover Subclassification",
      variable: "land_cover_sub_classification",
    },
    {
      label: "Landcover Other",
      variable: "land_cover_sub_classification_others",
    },
    {
      label: "Land Use Classification",
      variable: "land_use_classification",
    },
    {
      label: "Remarks",
      variable: "remarks",
    },
  ];

  const water_fieldwork_column = [
    {
      label: "Area Name",
      variable: "area_name",
    },
    {
      label: "Benthic Habitat Classification",
      variable: "benthic_habitat_classification",
    },
    {
      label: "Water Quality Indicator",
      variable: "water_quality_indicator",
    },
    {
      label: "Remarks",
      variable: "remarks",
    },
  ];

  const disaster_incidence_column = [
    {
      label: "Event Name",
      variable: "event_name",
    },
    {
      label: "Incident Type",
      variable: "incident_type",
    },
    {
      label: "Incident Others",
      variable: "incident_others",
    },
    {
      label: "Remarks",
      variable: "remarks",
    },
  ];

  const agricultural_survey_column = [
    {
      label: "Point Description",
      variable: "point_description",
    },
    {
      label: "Cropland Type",
      variable: "cropland_classification",
    },
    {
      label: "Cropland Type Others",
      variable: "cropland_classification_others",
    },
    {
      label: "Is Primary Crop",
      variable: "is_primary",
    },
    {
      label: "Date Planted",
      variable: "date_planted",
    },
    {
      label: "Crop Stage",
      variable: "crop_stage",
    },
    {
      label: "Crop Condition",
      variable: "crop_condition",
    },
    {
      label: "Crop Condition Others",
      variable: "crop_condition_others",
    },
    {
      label: "Remarks",
      variable: "remarks",
    },
  ];

  const getColumn = (odkProject) => {
    if (odkProject === "pinas_network") return landcover_column;
    if (odkProject === "water_fieldwork") return water_fieldwork_column;
    if (odkProject === "disaster_incidence") return disaster_incidence_column;
    if (odkProject === "agricultural_survey") return agricultural_survey_column;

    return landcover_column;
  };

  const column = getColumn(props.odkProject);
  // const column =
  //   props.odkProject == "disaster_incidence"
  //     ? disaster_incidence_column
  //     : landcover_column;

  // const drawer_title =
  //   props.odkProject == "disaster_incidence"
  //     ? "Disaster Incidence"
  //     : "Landcover Classification";
  const drawer_title = getDrawerTitle(props.odkProject);

  return (
    <div className="flex-grow-0 flex-shrink-0 w-1/3">
      <div
        className={`ease-out transition-all ${
          props.showODKDrawer &&
          "z-1500 shadow-md w-2/5 ease-in absolute overflow-visible top-0 right-0 left-auto flex flex-col h-full flex-grow flex-shrink-0 overflow-y-auto px-7 drawer-bg"
        }`}
      >
        {props.gettingODKDrawerData && (
          <div className="flex flex-col h-full drawer-margin">
            <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="5x"
                className="text-green-500"
              />
              {/* <i className="fas fa-circle-notch fa-spin fa-5x"></i> */}
            </span>
          </div>
        )}

        <div className="flex flex-col h-full">
          {!props.gettingODKDrawerData && (
            <div className="justify-start">
              <div className="top-2 left-3">
                <button
                  className="h-6 w-6 cursor-pointer rounded-full"
                  onClick={() => {
                    props.closeODKDrawer();
                  }}
                >
                  <XCircleIcon className="text-gray-500" />
                </button>
              </div>
            </div>
          )}

          {props.hasODKDrawerData && (
            <div class="flex flex-col h-full drawer-margin">
              {/* <div class="overflow-auto bg-white shadow sm:rounded-lg"> */}
              {/* <div class="bg-white shadow sm:rounded-lg"> */}
              <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {drawer_title}
                </h3>
              </div>
              <div class="border-t border-gray-200">
                <dl>
                  {column.map((col, col_i) => (
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        {col.label}
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {props.odkDrawerData[col.variable]}
                      </dd>
                    </div>
                  ))}
                  {/* metadata section */}
                  <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Submitted by
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {props.odkDrawerData.__system.submitterName}
                    </dd>
                  </div>
                  <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                      Submitted at
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {/* {props.odkDrawerData.start_time} */}
                      <Moment format="MMM DD, yyyy hh:mm:ss a">
                        {props.odkDrawerData.start_time}
                      </Moment>
                    </dd>
                  </div>
                  {/* Images section */}
                  <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Images</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div class="grid grid-cols-1 gap-y-10 gap-x-6 xl:gap-x-8">
                        {props.odkDrawerData.images.map((image, i_image) => (
                          <div class="group relative">
                            <div class="min-w-56 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:w-56">
                              <img
                                src={image}
                                alt=""
                                class="h-full w-full object-contain object-center"
                                onClick={() => props.showPictureModal(image)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showODKDrawer: state.mapReducer.showODKDrawer,
  odkDrawerData: state.mapReducer.odkDrawerData,
  gettingODKDrawerData: state.mapReducer.gettingODKDrawerData,
  hasODKDrawerData: state.mapReducer.hasODKDrawerData,
  odkProject: state.mapReducer.odkProject,
});

export default connect(mapStateToProps, { closeODKDrawer, showPictureModal })(
  withRouter(ODKDrawer)
);
