import React from "react";

const CardDetails = (props) => {
  return (
    <div className="flex flex-row w-full items-center py-2">
      <div className="flex-grow-0 flex-shrink-0 self-stretch w-9 text-left block">
        <img className="w-9" src={props.iconAsset} alt={props.altLabel} />
      </div>
      <div className="flex-auto self-stretch w-auto text-left block pl-3">
        <div className="text-sm text-gray-400 p-0">{props.statLabel}</div>
        <div className="text-base text-gray-700 p-0 -mt-1.5">
          {props.statDetail}
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
