import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Map, TileLayer, WMSTileLayer } from "react-leaflet";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import HeaderBar from "../../components/common/HeaderBar";
import Navbar from "../../components/navbar";
import ProjectLoader from "../../components/common/ProjectLoader";
import ProjectDetailAll from "./ProjectDetailAll";

import Covid19DataOutputs from "../../components/drawer/Covid19DataOutputs";
import ODKDrawer from "../../components/drawer/ODKDrawer";
import FloodMapView from "./FloodMapView";
import SatelliteInternetAvailability from "./SatelliteInternetAvailability";
import PinasNetwork from "./PinasNetwork";
import DisasterIncidence from "./DisasterIncidence";

import { setPointSelected } from "../../actions/http_request";

const initialViewport = {
  center: [14, 126],
  zoom: 6,
};

const Project = (props) => {
  const [viewport, setViewport] = useState(initialViewport);

  useEffect(() => {
    setViewport(
      {
        center: [13, 126],
        zoom: 6,
      },
      setViewport(initialViewport)
    );
  }, [props.location.pathname]);

  // const onViewportChanged = (viewport) => {
  //   setViewport(viewport);
  // };
  // console.log(props.match)

  return (
    <div className="flex flex-col top-0 left-0 bottom-0 right-0 absolute h-screen overflow-hidden antialiased">
      <HeaderBar />
      <div className="flex h-full">
        {/* <Navbar /> */}
        <div className="w-full h-full flex-grow relative">
          <div className="w-full h-full select-none">
            <div
              id="map-canvas"
              className="top-0 left-0 w-full h-full absolute"
            >
              <Map
                // onViewportChanged={onViewportChanged}
                className="w-full h-full relative overflow-hidden"
                viewport={viewport}
                maxZoom={30}
                minZoom={4}
                zoomControl={false}
                preferCanvas={true}
                worldCopyJump={true}
                style={{
                  cursor: props.selectedPointToggle ? "crosshair" : "",
                }}
                onClick={(event) => {
                  if (props.selectedPointToggle) {
                    // console.log(event.latlng.lat);
                    props.setPointSelected({
                      pointSelected: [event.latlng.lat, event.latlng.lng],
                    });
                  }
                }}
              >
                {/* <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url={
                    "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                  }
                /> */}

                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url={
                    "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                  }
                />

                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                  url={
                    "https://basemapserver.geoportal.gov.ph/tiles/v2/PGP/{z}/{x}/{y}.png"
                  }
                />

                <WMSTileLayer
                  url={`${process.env.REACT_APP_GEOSERVER_URL}`}
                  layers={`space-dashboard:wps-coast`}
                  // styles="novasar-images:raster-sar"
                  tileSize={256}
                  srs="EPSG:4326"
                  transparent={true}
                  tiled={true}
                  format="image/png"
                  // opacity={0.1}
                />

                <Switch>
                  {/* covid19 redirects to opera */}

                  <Route
                    path={`${props.match.path}`}
                    component={SatelliteInternetAvailability}
                  />
                  <Route
                    path={`${props.match.path}/project/covid19`}
                    render={() => (
                      <Redirect to={`${props.match.path}/project/opera`} />
                    )}
                  />

                  <Route
                    path={`${props.match.path}/project/flood`}
                    component={FloodMapView}
                  />
                  <Route
                    path={`${props.match.path}/project/leo`}
                    component={SatelliteInternetAvailability}
                  />
                  <Route
                    path={`${props.match.path}/project/pinas_network`}
                    component={PinasNetwork}
                  />
                  <Route
                    path={`${props.match.path}/project/disaster_incidence`}
                    component={DisasterIncidence}
                  />

                  <Route
                    path={`${props.match.path}/project/:project_id`}
                    component={ProjectDetailAll}
                  />

                  <Route
                    path={`${props.match.path}/`}
                    render={() =>
                      props.route === process.env.REACT_APP_ROUTE_COVID ? (
                        <Redirect to={`${props.match.path}/project/pedro`} />
                      ) : (
                        <Redirect to={`${props.match.path}/project/opera`} />
                      )
                    }
                  />
                  {/* <Route path={`${props.match.path}/`} component={ProjectList} exact /> */}
                </Switch>
              </Map>
            </div>
          </div>
          <Covid19DataOutputs />
          <ODKDrawer />
        </div>
      </div>
      <ProjectLoader />
    </div>
  );
};

const mapStateToProps = (state) => ({
  pointSelected: state.mapReducer.pointSelected,
  selectedPointToggle: state.mapReducer.selectedPointToggle,
});

export default connect(mapStateToProps, { setPointSelected })(
  withRouter(Project)
);
