import React from "react";

const PedroPopup = (props) => {
  return (
    <div className="font-sans">
      <h3 className="font-semibold text-base">{props.data.stakeholder}</h3>
      <h4 className="text-sm text-gray-400">
        {props.data.city},<span>&nbsp; {props.data.province}</span>
      </h4>
    </div>
  );
};

export default PedroPopup;
