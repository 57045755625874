import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_LOADING,
  LOGIN_ERROR,
  AUTH_ERROR,
  USER_LOADING,
  USER_LOADED,
  SHOW_LOADING,
  HIDE_LOADING,
} from "../actions/types";

export const authContextReducer = (state, action) => {
  switch (action.type) {
    case USER_LOADED:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isLoading: false,
        hasErrorMessage: false,
        isAuthenticated: true,
        is_admin: action.payload.is_admin ? true : false,
        is_staff: action.payload.is_staff ? true : false,
        user_id: action.payload.user_id,
        email: action.payload.email,
        token: action.payload.token,
      };
    case LOGOUT_SUCCESS:
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        isLoading: false,
        hasErrorMessage: false,
        isAuthenticated: false,
        is_admin: false,
        is_staff: false,
        user_id: 0,
        email: "",
        token: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        hasErrorMessage: true,
        isLoading: false,
      };
    case LOGIN_LOADING:
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
