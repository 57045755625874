import React, { Fragment, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

import { WMSTileLayer } from "react-leaflet";
import Control from "react-leaflet-control";

// reducer imports
import { connect } from "react-redux";
import { getFloodLayerTitles } from "../../actions/http_request";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

const FloodMapView = ({
  getFloodLayerTitles,
  floodLayerTitles,
  gettingFloodLayerTitles,
  hasFloodLayerTitles,
}) => {
  const [selectedFloodLayerTitle, setSelectedFloodLayerTitle] = useState(0);
  const controlRef = useRef();

  useEffect(() => {
    // if ("current" in controlRef)
    //   if (controlRef.current !== undefined && "props" in controlRef.current){
    //     controlRef.current.props.leaflet.map.scrollWheelZoom.disable()
    //     console.log(controlRef.current.props.leaflet.map)
    //   }
    if (!gettingFloodLayerTitles && !hasFloodLayerTitles) getFloodLayerTitles();
  }, [
    floodLayerTitles,
    gettingFloodLayerTitles,
    hasFloodLayerTitles,
    getFloodLayerTitles,
    selectedFloodLayerTitle,
  ]);

  if (!hasFloodLayerTitles) return null;

  // console.log(hasFloodLayerTitles);
  // console.log(selectedFloodLayerTitle.Name)

  return (
    <Fragment>
      <Control
        position="topleft"
        className="font-sans"
        ref={controlRef}
        // onMouseOver={()=>controlRef.current.props.leaflet.map.scrollWheelZoom.disable()}
        // onMouseOut={()=>controlRef.current.props.leaflet.map.scrollWheelZoom.enabled()}
      >
        <div
          className="w-60"
          onMouseOver={() => {
            // if(controlRef.current.props.leaflet.map.scrollWheelZoom.enabled()){
            //   controlRef.current.props.leaflet.map.scrollWheelZoom.disable()
            //   console.log('scroll disable')
            // }
            controlRef.current.props.leaflet.map.scrollWheelZoom.disable();
            // console.log('mouse over')
          }}
          onMouseOut={() => {
            // if(controlRef.current.props.leaflet.map.scrollWheelZoom.disable()){
            //   controlRef.current.props.leaflet.map.scrollWheelZoom.enable()
            //   console.log('scroll enabled')
            // }
            controlRef.current.props.leaflet.map.scrollWheelZoom.enable();
            // console.log('mouse out')
          }}
        >
          <Listbox
            value={selectedFloodLayerTitle}
            // onChange={setSelectedFloodLayerTitle}
            onChange={(e) => {
              controlRef.current.props.leaflet.map.scrollWheelZoom.enable();
              setSelectedFloodLayerTitle(e);
            }}
          >
            <Listbox.Button className="cursor-pointer relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
              <span className="block truncate">
                {selectedFloodLayerTitle === 0
                  ? "Select flood layer"
                  : selectedFloodLayerTitle.Name}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="h-80 max-h-full overflow-y-auto w-full py-1 mt-1 text-sm bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {floodLayerTitles.map((layer, layerIdx) => (
                  <Listbox.Option
                    key={layerIdx}
                    className={({ active }) =>
                      `${active ? "text-red-900 bg-red-100" : "text-gray-900"}
											cursor-default select-none relative py-2 pl-10 pr-4`
                    }
                    value={layer}
                  >
                    {({ selectedFloodLayerTitle, active }) => (
                      <>
                        <span
                          className={`${
                            selectedFloodLayerTitle ? "text-lg" : "text-base"
                          } block truncate`}
                        >
                          {layer.Name}
                        </span>
                        {selectedFloodLayerTitle ? (
                          <span
                            className={`${
                              active ? "text-red-600" : "text-red-600"
                            }
														absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon
                              className="w-5 h-5 text-gray-500"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
      </Control>

      {hasFloodLayerTitles === true && selectedFloodLayerTitle !== 0 && (
        <WMSTileLayer
          key={`layer_${selectedFloodLayerTitle.Name}`}
          url={`${process.env.REACT_APP_GEOSERVER_URL}`}
          layers={selectedFloodLayerTitle.Name}
          tileSize={256}
          srs="EPSG:4326"
          transparent={true}
          tiled={true}
          format="image/png"
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  floodLayerTitles: state.mapReducer.floodLayerTitles,
  gettingFloodLayerTitles: state.mapReducer.gettingFloodLayerTitles,
  hasFloodLayerTitles: state.mapReducer.hasFloodLayerTitles,
});

export default connect(mapStateToProps, {
  getFloodLayerTitles,
})(withRouter(FloodMapView));
