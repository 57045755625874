import React, { Fragment } from "react";

import DataTable from "react-data-table-component";
// import { Icon, Menu, Table, Dropdown } from "semantic-ui-react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { isInteger } from "lodash";

const DowntimeTable = (props) => {
  let sortTag = "duration";
  let sortDirection = -1;
  let sortedData = [];

  const columns = [
    {
      name: "Begin at",
      selector: (row) => row.beginTime,
      sortable: true,
    },
    {
      name: "End at",
      selector: (row) => row.endTime,
      sortable: true,
    },
    {
      name: "Duration (sec)",
      selector: (row) => row.duration,
      sortable: true,
    },
  ];

  sortedData = props.leoData.timetable
    .filter((f) => f.has_connection === false)
    .sort((a, b) => {
      var direction = 0;
      if (sortDirection === 1) {
        direction = 1;
      } else if (sortDirection === -1) {
        direction = -1;
      }

      if (a[sortTag] > b[sortTag]) return direction;
      else if (a[sortTag] < b[sortTag]) return -direction;
      else return 0;
    });

  const tableData = sortedData.map((item) => {
    item.beginTime = <Moment format="h:mm:ss a">{item.start}</Moment>;
    item.endTime = <Moment format="h:mm:ss a">{item.end}</Moment>;
    return item;
  });

  // console.log(tableData);

  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparitors
      const aField = selector(a);
      const bField = selector(b);

      const aValue = isInteger(aField) ? aField : aField.props.children;
      const bValue = isInteger(bField) ? bField : bField.props.children;

      // console.log(selector, a, aField, aValue);

      let comparison = 0;

      if (aValue > bValue) {
        comparison = 1;
      } else if (aValue < bValue) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  return (
    <Fragment>
      <h3 className="font-semibold text-base text-gray-700 py-3">
        Instances with 0 satellites
      </h3>
      <DataTable
        columns={columns}
        data={tableData}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 7, 10]}
        sortFunction={customSort}
      />
    </Fragment>
  );
};

const tableStateToProps = (state) => ({
  leoData: state.mapReducer.leoData,
});

export default connect(tableStateToProps, {})(withRouter(DowntimeTable));
