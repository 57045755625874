import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  getDisasterPoints,
  getPointDetails,
  setODKProject,
  cleanDrawer,
} from "../../actions/http_request";

import { connect } from "react-redux";

import Control from "react-leaflet-control";
import {
  Marker,
  GeoJSON,
  Circle,
  Popup,
  TileLayer,
  WMSTileLayer,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import PinasForm from "../../components/cards/PinasForm";

const PinasNetwork = (props) => {
  // const odkProject = "pinas_network";

  useEffect(() => {
    // props.setODKProject(odkProject);

    console.log("pinas report", props.hasDisasterPoints);
    if (!props.hasDisasterPoints) {
      console.log("get pinas points", props.odkProject);
      props.getDisasterPoints(props.odkProject);
    }

    return () => {
      console.log("pinas cleanup");
      props.cleanDrawer();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("get pinas points", props.odkProject);
    props.getDisasterPoints(props.odkProject);
    // eslint-disable-next-line
  }, [props.odkProject]);

  return (
    <Fragment>
      <Control position="topleft" className="font-sans">
        <PinasForm
          odkProject={props.odkProject}
          setODKProject={props.setODKProject}
        />
      </Control>

      <MarkerClusterGroup
        // iconCreateFunction={iconCreateFunction}
        maxClusterRadius={37}
      >
        {props.hasDisasterPoints &&
          props.disasterPoints.map((point, index) => (
            <Marker
              key={`disaster_list_${index}`}
              position={point.position}
              onClick={() => {
                props.getPointDetails(point, props.odkProject);
              }}
            ></Marker>
          ))}
      </MarkerClusterGroup>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  disasterPoints: state.mapReducer.disasterPoints,
  gettingDisasterPoints: state.mapReducer.gettingDisasterPoints,
  hasDisasterPoints: state.mapReducer.hasDisasterPoints,
  odkProject: state.mapReducer.odkProject,
});

export default connect(mapStateToProps, {
  getDisasterPoints,
  getPointDetails,
  setODKProject,
  cleanDrawer,
})(withRouter(PinasNetwork));
