import // LOGIN_SUCCESS,
// LOGOUT_SUCESS,
// AUTH_ERROR,
// USER_LOADING,
// USER_LOADED
"../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: true,
  isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
