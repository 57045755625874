import React from "react";

import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrosshairs,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const MapToolButtonGroup = (props) => {
  return (
    <div className={props.classname}>
      <div className="flex items-baseline flex-wrap">
        <div className="flex m-2">
          <Tippy content={<span>Point tool</span>} placement="right">
            <button className="btn hover:bg-blue-400 focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 mr-0.5">
              <div className="flex leading-5">
                <FontAwesomeIcon
                  fixedWidth
                  icon={faMapMarkerAlt}
                  className="text-lg text-gray-800 mx-2"
                  onClick={props.onClickAction1}
                />
              </div>
            </button>
          </Tippy>
          <Tippy content={<span>Geolocation tool</span>} placement="right">
            <button className="btn hover:bg-red-400 focus:ring-2 focus:ring-red-600 focus:ring-opacity-50">
              <div className="flex leading-5">
                <FontAwesomeIcon
                  fixedWidth
                  icon={faCrosshairs}
                  className="text-lg text-gray-800 mx-2"
                  onClick={props.onClickAction2}
                />
              </div>
            </button>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

export default MapToolButtonGroup;
