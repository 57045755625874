import React from "react";

const DatosPopup = (props) => {
  return (
    <div className="font-sans">
      <h3 className="popup-header">{props.data.stakeholder}</h3>
      <h4 className="popup-subheader">{props.data.acronym}</h4>
      <p className="py-1 my-0">
        Service Rendered: {props.data.service_rendered} <br />
        Data Provided: {props.data.data_provided}
      </p>
    </div>
  );
};

export default DatosPopup;
