import axios from "axios";
import wkt from "wkt";

import { WMSCapabilities } from "ol/format";

import Moment from "react-moment";

import {
  CHANGE_MAP_PARAMS,
  SHOW_LOADER,
  HIDE_LOADER,
  GETTING_FLOOD_GEOJSON_LIST,
  STORE_FLOOD_GEOJSON_LIST,
  GETTING_FLOOD_LAYER_TITLES,
  STORE_FLOOD_LAYER_TITLES,
  GETTING_FLOOD_FEATURE_COLLECTION,
  STORE_FLOOD_FEATURE_COLLECTION,
  SET_LEO_DATA,
  GETTING_LEO_DATA,
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SET_SELECTED_POINT_TOGGLE,
  SET_POINT_SELECTED,
  SHOW_DRAWER_LOADER,
  HIDE_DRAWER_LOADER,
  GETTING_DISASTER_POINTS,
  SET_DISASTER_POINTS,
  GETTING_ODK_DRAWER_DATA,
  SET_ODK_DRAWER_DATA,
  SET_ODK_PROJECT,
  CLEAN_ODK_DRAWER,
  SHOW_PICTURE_MODAL,
  HIDE_PICTURE_MODAL,
} from "./types";

import { queryFloodGeojsonList, queryLeoData } from "./query";

const query = async (params) => {
  let response;
  // console.log(params);
  try {
    response = await axios({ ...params });
  } catch (err) {
    console.log(err);
    console.log(err.response);
    if (err.response) response = err.response;
    else response = { status: 500, data: null };
  }
  return response;
};

const getTimestamp = () => {
  let timestamp = new Date().getTime();
  return timestamp;
};

export const loginRequest = async (data) => {
  const url = `${process.env.REACT_APP_API_AUTH_URL}login/`;

  let response = query({ method: "POST", url, data });

  return response;
};

export const loadUserRequest = async ({ token }) => {
  const url = `${process.env.REACT_APP_API_AUTH_URL}user/`;

  let response = query({
    method: "GET",
    url,
    headers: { Authorization: `Token ${token}` },
  });

  return response;
};

export const logoutRequest = async (token) => {
  const url = `${process.env.REACT_APP_API_AUTH_URL}logout/`;

  let response = query({
    method: "POST",
    url,
    data: null,
    headers: { Authorization: `Token ${token}` },
  });

  return response;
};

/// old

const api = axios.create({
  timeout: 1000 * 3600,
  // headers: { Authorization: `Token ${store.getState().authReducer.token}` }
});

export const getAgencies = (project_id) => (dispatch) => {
  let timestamp = Date.now();
  let url = `${process.env.REACT_APP_API_URL}agency/?timestamp=${timestamp}`;
  if (typeof project_id !== "undefined") {
    url += `&search=${project_id}`;
  }
  console.log(project_id, url);
  api({
    method: "get",
    url: url,
  })
    .then((resp) => {
      console.log(resp.data);
      dispatch({
        type: CHANGE_MAP_PARAMS,
        payload: {
          agencies: resp.data,
          hasAgencies: true,
        },
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const getProjects = () => (dispatch) => {
  let timestamp = Date.now();
  let url = `${process.env.REACT_APP_API_URL}project/?timestamp=${timestamp}`;
  // if (typeof project_id !== "undefined") {
  //   url += `&search=${project_id}`;
  // }
  // console.log(project_id, url);
  api({
    method: "get",
    url: url,
  })
    .then((resp) => {
      console.log(resp.data);
      dispatch({
        type: CHANGE_MAP_PARAMS,
        payload: {
          projects: resp.data,
          hasProjects: true,
        },
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const resetProjectData = () => (dispatch) => {
  dispatch({
    type: CHANGE_MAP_PARAMS,
    payload: {
      hasProjectData: false,
    },
  });
};

export const toggleControl = (tag_name) => (dispatch, getState) => {
  let operaDetailAllTags = getState().mapReducer.operaDetailAllTags;
  let newTags = operaDetailAllTags.map((t) => {
    if (t.dataset_id === tag_name || t.tag === tag_name) t.checked = !t.checked;
    return t;
  });
  dispatch({
    type: CHANGE_MAP_PARAMS,
    payload: {
      operaDetailAllTags: [...newTags],
    },
  });
  console.log(newTags);
};

export const getProjectAll = (project_id) => (dispatch) => {
  dispatch({
    type: CHANGE_MAP_PARAMS,
    payload: {
      hasOperaData: false,
      hasLoader: true,
      drawerVisible: false,
    },
  });
  // let timestamp = Date.now();
  let url = `${process.env.REACT_APP_API_URL}${project_id}/`;
  // console.log(url);

  api({
    method: "get",
    url: url,
  })
    .then((resp) => {
      // console.log(resp.data.tags)
      resp.data.tags.forEach((tag) => {
        tag.value =
          project_id === "opera"
            ? resp.data.summary.find((x) => x.label === tag.name).value
            : resp.data.summary.find((x) => x.label === tag.label).value;
      });
      dispatch({
        type: CHANGE_MAP_PARAMS,
        payload: {
          operaData: resp.data.data,
          operaDetailAllTags: resp.data.tags,
          operaTitle: resp.data.title,
          operaSummary: resp.data.summary,
          hasSummary: resp.data.show_summary,
          hasControl: resp.data.has_control,
          hasOperaData: true,
          hasLoader: false,
        },
      });
      //console.log(resp.data)
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const getProjectData =
  (project_id, project_data, has_project) => (dispatch) => {
    dispatch({
      type: CHANGE_MAP_PARAMS,
      payload: {
        [has_project]: false,
        hasLoader: true,
      },
    });
    // let timestamp = Date.now();
    let timestamp = 22;
    let url = `${process.env.REACT_APP_API_URL}${project_id}/?timestamp=${timestamp}`;

    api({
      method: "get",
      url: url,
    })
      .then((resp) => {
        console.log(resp.data);
        dispatch({
          type: CHANGE_MAP_PARAMS,
          payload: {
            [project_data]: resp.data,
            [has_project]: true,
            hasLoader: false,
          },
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

export const getRequests = (agency_id) => (dispatch) => {
  let timestamp = Date.now();
  let url = `${process.env.REACT_APP_API_URL}request/?timestamp=${timestamp}`;
  if (typeof agency_id !== "undefined") url += `&agency=${agency_id}`;

  api({
    method: "get",
    url: url,
  })
    .then((resp) => {
      const data = resp.data.map((req) => {
        var temp_pol = req.geom_multipolygon.split(")), ((");

        req.multi_p = temp_pol.map((pol) => {
          // console.log(pol);
          const pol_points = pol
            .replace("SRID=4326;MULTIPOLYGON (((", "")
            .replace(")", "")
            .split(", ");
          const point_array = pol_points.map((point) => {
            const p = point.split(" ");
            return [parseFloat(p[1]), parseFloat(p[0])];
            // return p;
          });
          return point_array;
        });

        return req;
      });

      console.log(data);
      dispatch({
        type: CHANGE_MAP_PARAMS,
        payload: {
          requests: data,
          hasRequests: true,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
    });
};

export const passDataToDrawer = (project, data, visible) => (dispatch) => {
  if (project === "opera") {
    dispatch({ type: SHOW_DRAWER_LOADER });

    let url = `${process.env.REACT_APP_API_URL}opera/get_observation/${data.observation_id}`;
    console.log(url);
    api({
      method: "get",
      url: url,
    }).then((resp) => {
      dispatch({ type: HIDE_DRAWER_LOADER });
      dispatch({
        type: CHANGE_MAP_PARAMS,
        payload: {
          drawerData: data,
          drawerMeasurement: resp.data,
          drawerVisible: visible,
        },
      });
    });
  } else if (project === "captured") {
    dispatch({
      type: CHANGE_MAP_PARAMS,
      payload: {
        drawerData: data,
        drawerVisible: visible,
      },
    });
  }
};

export const showWelcomeDrawer = (visible) => (dispatch) => {
  dispatch({
    type: CHANGE_MAP_PARAMS,
    payload: {
      drawerWelcome: visible,
    },
  });
};

export const getFloodGeojsonList = () => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  dispatch({ type: GETTING_FLOOD_GEOJSON_LIST });

  const url = `${
    process.env.REACT_APP_API_URL
  }flood_geojson/?&timestamp=${getTimestamp()}`;

  let result = await queryFloodGeojsonList(url);
  let { data, status } = result;

  if (status === 200) {
    dispatch({
      type: STORE_FLOOD_GEOJSON_LIST,
      payload: {
        floodGeojsonList: data,
      },
    });
    dispatch({ type: HIDE_LOADER });
  }
};

export const getFloodLayerTitles = () => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  dispatch({ type: GETTING_FLOOD_LAYER_TITLES });

  const url =
    "https://mk2.philsa.gov.ph/geoserver/flood-maps/wms?request=GetCapabilities&service=WMS&version=1.1.1";

  let result = await queryFloodGeojsonList(url);
  let { data, status } = result;

  // console.log(floodmaps_layer_list);

  if (status === 200) {
    let formatter = new WMSCapabilities();
    const floodmaps_data = formatter.read(data);
    const floodmaps_layer_list = floodmaps_data.Capability.Layer.Layer;
    console.log(floodmaps_data.Capability);

    dispatch({
      type: STORE_FLOOD_LAYER_TITLES,
      payload: {
        floodLayerTitles: floodmaps_layer_list,
      },
    });
    dispatch({ type: HIDE_LOADER });
  }
};

export const getFloodFeature = (flood_geojson_id) => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  dispatch({ type: GETTING_FLOOD_FEATURE_COLLECTION });

  let url = `${
    process.env.REACT_APP_API_URL
  }flood_feature/wkt_list/?flood_geojson=${flood_geojson_id}&timestamp=${getTimestamp()}`;

  let flood_features = [];

  while (1) {
    let result = await queryFloodGeojsonList(url);
    let { data, status } = result;

    if (status === 200) {
      flood_features = [...flood_features, ...data.results];

      // break;

      if (data.next === null) break;
      else url = data.next;
    } else {
      dispatch({ type: HIDE_LOADER });
      return null;
    }
  }

  // console.log(flood_features);

  const featureCollection = {
    type: "FeatureCollection",
    features: flood_features.map((d) => {
      return {
        type: "feature",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
        },
        geometry: wkt.parse(d.geom_poly),
      };
    }),
  };

  let coordinates = [];

  featureCollection.features.forEach((feature) => {
    coordinates = [...coordinates, ...feature.geometry.coordinates];
  });

  const MultiPolygon = {
    type: "MultiPolygon",
    crs: featureCollection.features[0].geometry.crs,
    coordinates: coordinates,
  };

  // console.log(featureCollection);

  dispatch({
    type: STORE_FLOOD_FEATURE_COLLECTION,
    payload: {
      floodFeatureCollection: MultiPolygon,
    },
  });
  dispatch({ type: HIDE_LOADER });
};

// leo http request
export const getLeoData =
  ({ params }) =>
  async (dispatch) => {
    dispatch({ type: SHOW_LOADER });
    dispatch({ type: GETTING_LEO_DATA });

    let start = new Date();
    let { data, status } = await queryLeoData({ params });
    let end = new Date();
    console.log("Query Time:", start, end);
    if (status === 200) {
      dispatch({
        type: SET_LEO_DATA,
        payload: {
          leoData: data,
        },
      });
    } else {
      console.log("QUERY ERROR");
    }

    dispatch({ type: HIDE_LOADER });
    console.log(data);
  };

export const toggleSidebarPanel = ({ dispatch, isVisible }) => {
  // console.log(isVisible);
  if (isVisible) dispatch({ type: HIDE_SIDEBAR });
  else dispatch({ type: SHOW_SIDEBAR });
};

export const toggleSelectPoint = () => (dispatch) => {
  console.log("toggleSelectPoint");
  dispatch({ type: SET_SELECTED_POINT_TOGGLE });
};

export const setPointSelected =
  ({ pointSelected }) =>
  (dispatch) => {
    console.log("setPointSelected");
    console.log(pointSelected);
    dispatch({ type: SET_POINT_SELECTED, payload: { pointSelected } });
  };

export const getActiveSatellites = () => async (dispatch) => {
  dispatch({
    type: CHANGE_MAP_PARAMS,
    payload: {
      hasActiveSatellites: false,
      hasLoader: true,
    },
  });
  // let timestamp = Date.now();
  let url = `${process.env.REACT_APP_API_URL}/sat_available/`;
  // console.log(url);

  api({
    method: "get",
    url: url,
  })
    .then((resp) => {
      //console.log(resp.data)
      dispatch({
        type: CHANGE_MAP_PARAMS,
        payload: {
          activeSatellites: resp.data,
          hasActiveSatellites: true,
          hasLoader: false,
        },
      });
    })
    .catch((err) => {
      console.log(err.response);
    });
};

const getBasePinasUrl = (odkProject) => {
  if (odkProject === "pinas_network")
    return process.env.REACT_APP_ODK_PINAS_NETWORK_URL;
  if (odkProject === "water_fieldwork")
    return process.env.REACT_APP_ODK_WATER_FIELDWORK;
  if (odkProject === "disaster_incidence")
    return process.env.REACT_APP_ODK_DISASTER_INCIDENCE_URL;
  if (odkProject === "agricultural_survey")
    return process.env.REACT_APP_ODK_AGRICULTURAL_SURVEY_URL;

  // default
  return process.env.REACT_APP_ODK_PINAS_NETWORK_URL;
};

export const getDisasterPoints = (odkProject) => async (dispatch) => {
  dispatch({
    type: GETTING_DISASTER_POINTS,
  });

  // const url = "https://odk.philsa.gov.ph/v1/projects/2/forms/Land-Cover-Fieldwork.svc/Submissions"

  // const auth = {
  //   username: process.env.REACT_APP_ODK_EMAIL,
  //   password: process.env.REACT_APP_ODK_PASSWORD
  // }

  const url = `${process.env.REACT_APP_API_URL}odk_api_query`;
  const odk_url = `${getBasePinasUrl(
    odkProject
  )}.svc/Submissions?$filter=__system/reviewState%20eq%20'approved'`;
  // const odk_url =
  //   odkProject == "disaster_incidence"
  //     ? `${process.env.REACT_APP_ODK_DISASTER_INCIDENCE_URL}.svc/Submissions?$filter=__system/reviewState%20eq%20'approved'`
  //     : `${process.env.REACT_APP_ODK_PINAS_NETWORK_URL}.svc/Submissions?$filter=__system/reviewState%20eq%20'approved'`;

  console.log(odk_url);

  let { status, data } = await query({
    method: "POST",
    url: url,
    data: {
      url: odk_url,
    },
  });

  console.log(status);

  if (status === 200) {
    const disasterPoints = data.value
      // .filter((d) => d.__system.reviewState == "approved")
      .map((d) => {
        return {
          ...d,
          position: [d.location.coordinates[1], d.location.coordinates[0]],
          __system: {
            ...d.__system,
            submissionDate: <Moment>{d.__system.submissionDate}</Moment>,
          },
        };
      });

    dispatch({
      type: SET_DISASTER_POINTS,
      payload: {
        disasterPoints: disasterPoints,
      },
    });

    console.log(disasterPoints);
  } else {
    // TODO: need alert for API Fail
    dispatch({
      type: CHANGE_MAP_PARAMS,
      payload: {
        hasLoader: false,
      },
    });
    alert("ODK API currently down.");
  }
};

export const getPointDetails = (point, odkProject) => async (dispatch) => {
  console.log(point);
  dispatch({
    type: GETTING_ODK_DRAWER_DATA,
  });

  const photogroup_link = point["photogroup@odata.navigationLink"];
  console.log(photogroup_link);

  const image_url = `${getBasePinasUrl(odkProject)}/submissions`;
  // const image_url =
  //   odkProject == "disaster_incidence"
  //     ? `${process.env.REACT_APP_ODK_DISASTER_INCIDENCE_URL}/submissions`
  //     : `${process.env.REACT_APP_ODK_PINAS_NETWORK_URL}/submissions`;

  // const primary_photo = "";

  // const base_url =
  //   point.start_time > "2022-02-01T00:00:00.000+08:00"
  //     ? `${process.env.REACT_APP_API_URL}odk_image_query?url=${image_url}/${point.meta.instanceID}/attachments/`
  //     : "https://storage.googleapis.com/pinas-network-bucket/pinas-media/";
  const base_url = `${process.env.REACT_APP_API_URL}odk_image_query?url=${image_url}/${point.meta.instanceID}/attachments/`;

  const primary_photo = `${base_url}${point.primary_photo}`;

  if (typeof photogroup_link === "undefined") {
    const images = point.primary_photo === null ? [] : [primary_photo];

    dispatch({
      type: SET_ODK_DRAWER_DATA,
      payload: {
        odkDrawerData: { ...point, images },
      },
    });
    return;
  }

  const photogroup_url = `${getBasePinasUrl(
    odkProject
  )}.svc/${photogroup_link}`;
  // const photogroup_url =
  //   odkProject == "disaster_incidence"
  //     ? `${process.env.REACT_APP_ODK_DISASTER_INCIDENCE_URL}.svc/${photogroup_link}`
  //     : `${process.env.REACT_APP_ODK_PINAS_NETWORK_URL}.svc/${photogroup_link}`;

  const url = `${process.env.REACT_APP_API_URL}odk_api_query`;
  const { status, data } = await query({
    method: "POST",
    url: url,
    data: {
      url: photogroup_url,
    },
  });

  console.log(status);

  if (status === 200) {
    const submission_id = photogroup_link
      .split("Submissions('")
      .pop()
      .split("')/photogroup")[0];
    // str.split(':').pop().split(';')[0];
    console.log(submission_id);
    console.log(data);

    const images = data.value
      .filter((d) => d.photo !== null)
      .map((d) => {
        // return `${process.env.REACT_APP_API_URL}odk_image_query?url=${image_url}/${submission_id}/attachments/${d.photo}`;
        return `${process.env.REACT_APP_API_URL}odk_image_query?url=${image_url}/${point.meta.instanceID}/attachments/${d.photo}`;
      });

    console.log(images);

    const all_images =
      "photo" in point ? [primary_photo, ...images] : [...images];

    dispatch({
      type: SET_ODK_DRAWER_DATA,
      payload: {
        odkDrawerData: { ...point, images: all_images },
      },
    });
  } else {
    // TODO: need alert for API Fail
    dispatch({
      type: CHANGE_MAP_PARAMS,
      payload: {
        gettingODKDrawerData: false,
      },
    });
    alert("ODK API currently down.");
  }
};

export const closeODKDrawer = () => async (dispatch) => {
  dispatch({
    type: CHANGE_MAP_PARAMS,
    payload: {
      showODKDrawer: false,
    },
  });
};

export const setODKProject = (odkProject) => (dispatch) => {
  console.log(odkProject);
  dispatch({
    type: SET_ODK_PROJECT,
    payload: {
      odkProject,
    },
  });
};

export const cleanDrawer = () => (dispatch) => {
  dispatch({
    type: CLEAN_ODK_DRAWER,
  });
};

export const showPictureModal = (url) => (dispatch) => {
  dispatch({
    type: SHOW_PICTURE_MODAL,
    payload: {
      pictureModalUrl: url,
    },
  });
};

export const hidePictureModal = () => (dispatch) => {
  dispatch({
    type: HIDE_PICTURE_MODAL,
  });
};
