import React from "react";

const PinasForm = ({ odkProject, setODKProject }) => {
  return (
    <div className="px-4 m-2 py-2 max-w-xxs rounded overflow-hidden shadow-md bg-gray-100">
      <div class="col-span-6 sm:col-span-3">
        <label for="country" class="block text-sm font-medium text-gray-700">
          Form
        </label>
        <select
          id="country"
          name="country"
          autocomplete="country-name"
          onChange={(e) => {
            // console.log(e.target.value);
            setODKProject(e.target.value);
          }}
          class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          <option value="pinas_network">Land Cover Fieldwork</option>
          <option value={"water_fieldwork"}>Water Fieldwork</option>
          <option value={"disaster_incidence"}>Disaster Instance</option>
          <option value={"agricultural_survey"}>Agricultural Survey</option>
        </select>
      </div>
    </div>
  );
};

export default PinasForm;
