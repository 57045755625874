import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import asti_logo from "../../assets/media/asti.png";
import s4s_logo from "../../assets/media/s4s.png";
import philsa_logo from "../../assets/media/PhilSA_v2.png";

import { getProjectAll } from "../../actions/http_request";
// import { getTitle } from "./proj"

const HeaderNav = (props) => {
  // const title = getTitle(props.match.params.project_id);
  const currentUrl = props.location.pathname;

  return (
    <header className="flex flex-col box-border static max-w-full flex-shrink-0 px-4 sm:px-6 bg-green-400 justify-center items-center">
      <div className="grid grid-cols=3 grid-flow-col gap-3 p-2 items-center">
        <div className="flex justify start align-middle">
          <div>
            <div className="flex flex-row overflow-hidden">
              <a href="https://asti.dost.gov.ph">
                <img
                  src={asti_logo}
                  className="h-10 w-10"
                  alt="satellite data"
                ></img>
              </a>
              <a href="https://stamina4space.upd.edu.ph">
                <img
                  src={s4s_logo}
                  className="h-10 w-10"
                  alt="satellite data"
                ></img>
              </a>
              <a href="http://philsa.gov.ph">
                <img
                  src={philsa_logo}
                  className="h-10 w-10"
                  alt="satellite data"
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="flex text-gray-700 font-bold text-lg ml-4 uppercase">
          {currentUrl === "/spacedata/project/flood"
            ? "Flood Maps"
            : currentUrl === "/tracker"
            ? // ? "Satellite Internet Availability"
              "LEO Internet Constellation Tracker"
            : props.projectDetailTitle}
        </div>
      </div>
    </header>
  );
};

const projectTitleStateToProps = (state) => ({
  projectDetailTitle: state.mapReducer.projectDetailTitle,
});

export default connect(projectTitleStateToProps, {
  getProjectAll,
})(withRouter(HeaderNav));
