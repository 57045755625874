import axios from "axios";
// import wkt from "wkt";

const query = async (params) => {
  let response;
  try {
    response = await axios({ ...params });
  } catch (err) {
    if (err.response) response = err.response;
    else response = { status: 500, data: null };
  }
  return response;
};

// const getTimestamp = () => {
//   let timestamp = new Date().getTime();
//   return timestamp;
// };

export const queryFloodGeojsonList = async (url) => {
  // const url = `${
  //   process.env.REACT_APP_API_URL
  // }platform_data_v4/?timestamp=${getTimestamp()}`;
  // const final_url = `${url}&timestamp=${getTimestamp()}`;
  // console.log(final_url);

  let response = query({
    method: "GET",
    url: url,
    // headers: { Authorization: `Token ${token}` },
  });

  return response;
};

// leo data
export const queryLeoData = async ({ params }) => {
  // const url = `${process.env.REACT_APP_API_URL}sat_track/`;
  // const url = `${process.env.REACT_APP_API_URL}sat_test/`;
  const url = `${process.env.REACT_APP_API_URL}sat_skyfield/`;
  console.log(url);
  let response = query({
    method: "GET",
    url,
    params: params,
    // headers: { Authorization: `Token ${token}` },
  });
  console.log(response);
  return response;
};
