export const options =  {
    responsive: true,
    animation: false,
    plugins: {
        legend: {
            position: 'bottom',
            align: 'center',
            labels: {
                usePointStyle: true,
            },
        },
        elements: {
            line: {
                tension: 0
            }
        },
        tooltip: {
          callbacks:{
            title: context => {
              console.log(context)
              const d = new Date(context[0].parsed.x);
              const formattedDate = d.toLocaleString([], {
                month: 'short',
                day: 'numeric',
              });
              return formattedDate;
            },
          }
        }
    },
    scales: {
      y: {
        title:{
            display: true,
            // text: 'value',      
            text: 'umol / m^2', 
            // text: 'umol/m<sup>2</sup>',      
            color: 'black'      
        },
        ticks: {
            display: true,
            color: 'rgba(0,0,0,1)'
        },
        grid: {
            display: true,
            color: 'rgba(0,0,0,0.3)'
        },
      },
      x: {
        title:{
            display: false,
            text: 'Date',
            color: 'black'
        },
        ticks: {
            display: true,
            maxRotation: 45,
            minRotation: 45,
            color: 'rgba(0,0,0,1)'
        },
        grid: {
            display: true,
            color: 'rgba(0,0,0,0.35)'
        },
        type: 'time',
        time: {
            unit: 'month',
            displayFormats: {
                month: 'MMM'
            }
        }
      }
    },
};

const filterDateChart = (dataList, date, startDate, endDate) => {
    if(startDate !== null) {
        if (endDate === null) {
            date = date.filter(dateFilter => new Date(dateFilter) >= startDate)
            dataList = dataList.map(d => d.filter(dateFilter => new Date(dateFilter.datetime) >= startDate))
        } else {
            date = date.filter(dateFilter => new Date(dateFilter) >= startDate && new Date(dateFilter) <= endDate)
            dataList = dataList.map(d => d.filter(dateFilter => new Date(dateFilter.datetime) >= startDate && new Date(dateFilter.datetime) <= endDate))
        }      
    }

    if(endDate !== null) {
        if (startDate === null) {
            date = date.filter(dateFilter => new Date(dateFilter) <= endDate)
            dataList = dataList.map(d => d.filter(dateFilter => new Date(dateFilter.datetime) <= endDate))
        } else {
            date = date.filter(dateFilter => new Date(dateFilter) >= startDate && new Date(dateFilter) <= endDate)
            dataList = dataList.map(d => d.filter(dateFilter => new Date(dateFilter.datetime) >= startDate && new Date(dateFilter.datetime) <= endDate))
        }      
    }

    return {date: date, dataList: dataList}
}

export const generateData = (data, state, startDate, endDate) => {
    var generateChart = []
    var dataList = []
    var date = []
    var value = []
    var result = []
    
    if(data.code === 'OBS_ID_NOT_EXIST'){
        return {
            'graph_data': {
                labels: [],
                datasets: []
            }
        }
    }

    if (state === true && typeof data !== 'undefined') {
        var tempVariables = data
        var tempData = data.measurements

        var labels = []
        // remove std data
        // tempVariables = tempVariables.filter(label => label.name.includes('std') == false)
        // tempVariables.map(label => labels.push(label.name))

        // tempVariables.forEach(
        //     variable => {
        //         dataList.push(tempData.filter(data => data.observable_id === variable.name))
        //     }
        // )

        dataList.push(tempData.filter(data => data.observable_id === tempVariables.id))

        dataList.forEach(
            variable => {
                variable.sort((date1, date2) => new Date(date1.datetime) - new Date(date2.datetime))
                        .map(tempDate => date.push(tempDate.datetime))
            }
        )

        if (!isNaN(startDate) && !isNaN(endDate)) {
            date = filterDateChart(dataList, date, startDate, endDate).date
            dataList = filterDateChart(dataList, date, startDate, endDate).dataList
        }

        result = [...new Set(date.map(event => new Date(event).getFullYear()))]
    }

    let datasets = [];
    let metrics = [];
    // let colors = ['rgb(255, 99, 132)', 'rgba(25, 118, 210)', 'rgba(211, 47, 47)', 'rgba(123, 31, 162)', 'rgba(0, 150, 136)']
    let colors = [
        // 'rgb(0, 153, 51)', 
        // 'rgba(25, 118, 210)', 
        // 'rgba(211, 47, 47)', 
        // 'rgb(204, 204, 0)', 
        'rgb(1, 26, 81)',
        'rgb(72, 123, 234)',
        'rgb(250, 177, 41)',
        'rgb(184, 55, 0)',
    ]

    dataList.forEach((d, i) => {
        if( d.length === 0){
            return {}
        }

        let color = shuffle(colors)[i];

        // datasets.push({
        //     label: d[0].observable_id,
        //     data: d.map(_ => {return {x: new Date(_.datetime), y: _.value}}),
        //     borderColor: color,
        //     borderWidth: 1,
        //     backgroundColor: color,
        //     lineTension: 0.25,
        //     pointStyle: "circle",
        //     pointRadius: 1,
        //     // lineTension: 0.25
        // })

        let measurementArray = d.map(x=>x.value);

        metrics.push({
            variable: d[0].observable_id,
            latest: {
                value: d[d.length - 1].value,
                percentChange: (
                    (measurementArray[measurementArray.length - 1] - 
                        measurementArray[measurementArray.length - 2]) / measurementArray[measurementArray.length - 2]) * 100,
                label: 'LATEST READING'
            },
            average: {
                value: measurementArray.reduce((i, j) => i + j, 0) / measurementArray.length,
                label: 'AVERAGE'
            }
        })
    })

    
    let dsets = []

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    dataList.forEach(
        dt => result.forEach(
            (res, i) => {
                let t = dt.filter(
                    d => new Date(d.datetime).getFullYear() === res
                )

                dsets.push(
                    {
                        // label: `${t[0].observable_id} - ${res}`,
                        label: `${res}`,
                        data: t.map(_ => {return {x: new Date(`${monthNames[new Date(_.datetime).getMonth()]} ${new Date(_.datetime).getDate()} 2001`), y: _.value}}),
                        borderColor: colors[i],
                        // borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        backgroundColor: colors[i],
                        // lineTension: 0.25,
                        pointStyle: "circle",
                        pointRadius: 3,
                        yAxisID: 'y'
                    }
                )
            }
        )
    )
    // console.log(dataList)
    // console.log(dsets)
    return {
        'graph_data': {
            // labels: date.date,
            datasets: dsets
        },
        'metrics': metrics
    }
}

export const downloadCSV = (data, name) => {
    var csv = "";

    Object.keys(data['downloads'][0]).forEach(
        key => csv += `${key},`
    )

    csv = csv.slice(0, -1);
    csv = csv + "\n";

    data['downloads'].forEach(
        row => {
            let keys = Object.keys(data['downloads'][0])

            keys.forEach(key => csv += `${row[key]},`)
            csv = csv.slice(0, -1);
            csv = csv + "\n";
        }
    )

    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'

    hiddenElement.download = `${name}_OPERA_Space_Data_Dashboard.csv`
    hiddenElement.click()
}

function shuffle(array) {
    let counter = array.length;

    // While there are elements in the array
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    return array;
}