import React from "react";

const PedroPopup = (props) => {
  console.log(props.data);
  return (
    <div className="font-sans">
      <h3 className="popup-header">{props.data.name}</h3>
      <h4 className="popup-subheader">{props.data.agency_type}</h4>
      <p className="py-1 my-0">
        Image request(s) total: {props.data.total_requests} <br />
        Total image(s) received: {props.data.total_images} <br />
      </p>
    </div>
  );
};

export default PedroPopup;
