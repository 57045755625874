import React, { Fragment, useState } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { hidePictureModal } from "../../actions/http_request";

import NavbarItem from "./NavbarItem";
import InstructionModal from "../modals/InstructionModal";
import PictureModal from "../modals/PictureModal";

// image assets
import Covid19Icon from "../../assets/media/spc_dsh_covid19.svg";
import PedroIcon from "../../assets/media/spc_dsh_PEDRO.svg";
import DatosIcon from "../../assets/media/spc_dsh_DATOS.svg";
import SarWaisIcon from "../../assets/media/spc_dsh_SARwAIS.svg";
import S4sIcon from "../../assets/media/spc_dsh_S4S.svg";
import FloodMapsIcon from "../../assets/media/spc_dsh_FloodMaps.svg";
import LEOIcon from "../../assets/media/spc_leo.svg";
import HelpIcon from "../../assets/media/spc_help.svg";
import PinasIcon from "../../assets/media/pinas.svg";
import DRIcon from "../../assets/media/disaster_incidence.svg";

const Navbar = (props) => {
  const [showModal, setShowModal] = useState(true);
  const redirectNav = (route) => {
    props.history.push(route);
  };

  return (
    <Fragment>
      <aside className="overflow-hidden bg-white focus:outline-none relative shadow-lg">
        <nav className="flex w-16 h-full items-center flex-col px-2 py-4 border-r">
          <div className="flex flex-col space-y-4 items-start justify-start flex-grow">
            {/* <NavbarItem
              navBarItemAltName={"Covid-19 icon"}
              navBarIcon={Covid19Icon}
              navBarIconAlt={"COVID-19"}
              navBarFunction={() =>
                redirectNav(
                  `/${process.env.REACT_APP_ROUTE_COVID}/project/opera`
                )
              }
              tooltipName={"OPERA"}
            />
            <NavbarItem
              navBarItemAltName={"PEDRO icon"}
              navBarIcon={PedroIcon}
              navBarIconAlt={"PEDRO"}
              navBarFunction={() =>
                redirectNav(
                  `/${process.env.REACT_APP_ROUTE_COVID}/project/pedro`
                )
              }
              tooltipName={"PEDRO"}
            />
            <NavbarItem
              navBarItemAltName={"DATOS icon"}
              navBarIcon={DatosIcon}
              navBarIconAlt={"DATOS"}
              navBarFunction={() =>
                redirectNav(
                  `/${process.env.REACT_APP_ROUTE_COVID}/project/datos`
                )
              }
              tooltipName={"DATOS"}
            />
            <NavbarItem
              navBarItemAltName={"SARwAIS icon"}
              navBarIcon={SarWaisIcon}
              navBarIconAlt={"SARwAIS"}
              navBarFunction={() =>
                redirectNav(
                  `/${process.env.REACT_APP_ROUTE_COVID}/project/novasar`
                )
              }
              tooltipName={"SARwAIS"}
            />
            <NavbarItem
              navBarItemAltName={"Stamina4Space icon"}
              navBarIcon={S4sIcon}
              navBarIconAlt={"Stamina4Space"}
              navBarFunction={() =>
                redirectNav(`/${process.env.REACT_APP_ROUTE_COVID}/project/s4s`)
              }
              tooltipName={"STAMINA4Space"}
            /> 
            <NavbarItem
              navBarItemAltName={"Flood Maps icon"}
              navBarIcon={FloodMapsIcon}
              navBarIconAlt={"Flood Maps"}
              navBarFunction={() =>
                redirectNav(
                  `/${process.env.REACT_APP_ROUTE_COVID}/project/flood`
                )
              }
              tooltipName={"Flood Maps"}
            /> */}
            <NavbarItem
              navBarItemAltName={"Satellite Internet Availability"}
              navBarIcon={LEOIcon}
              navBarIconAlt={"Satellite Internet Availability"}
              navBarFunction={() =>
                redirectNav(`/${process.env.REACT_APP_ROUTE_COVID}/project/leo`)
              }
              tooltipName={"LEO Satellite Internet Availability"}
            />

            {/* <NavbarItem
              navBarItemAltName={"PINAS Network"}
              navBarIcon={PinasIcon}
              navBarIconAlt={"PINAS Network"}
              navBarFunction={() =>
                redirectNav(
                  `/${process.env.REACT_APP_ROUTE_COVID}/project/pinas_network`
                )
              }
              tooltipName={"PINAS Network"}
            /> */}

            {/* <NavbarItem
              navBarItemAltName={"Disaster Incidence"}
              navBarIcon={DRIcon}
              navBarIconAlt={"Disaster Incidence"}
              navBarFunction={() =>
                redirectNav(`/${process.env.REACT_APP_ROUTE_COVID}/project/disaster_incidence`)
              }
              tooltipName={"Disaster Incidence"}
            /> */}
          </div>
          {/* <div>
            <hr className="h-px border-none bg-gray-300 flex-shrink-0 mx-0 mb-2"></hr>
            <NavbarItem
              navBarItemAltName={"Help icon"}
              navBarIcon={HelpIcon}
              navBarIconAlt={"How to"}
              navBarFunction={() => setShowModal(true)}
              tooltipName={"Help"}
            />
          </div> */}
        </nav>
      </aside>
      {/* {showModal ? (
        <InstructionModal setModalVisibility={() => setShowModal(false)} />
      ) : null}
      {props.showPictureModal && (
        <PictureModal
          pictureModalUrl={props.pictureModalUrl}
          setModalVisibility={() => props.hidePictureModal()}
        />
      )} */}
    </Fragment>
  );
};

// export default withRouter(Navbar);
const mapStateToProps = (state) => ({
  showPictureModal: state.mapReducer.showPictureModal,
  pictureModalUrl: state.mapReducer.pictureModalUrl,
});

export default connect(mapStateToProps, { hidePictureModal })(
  withRouter(Navbar)
);
