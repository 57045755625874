import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";

const CircularButton = (props) => {
  return (
    <div className="m-2">
      <Tippy content={<span>{props.tooltipName}</span>} placement="right">
        <button
          className="p-1 w-9 h-9 rounded-full hover:scale-110 focus:outline-none flex justify-center active:shadow-md mouse shadow transition ease-in duration-200 hover:bg-green-400  
        bg-gray-100 text-gray-700 "
          onClick={props.onClickAction}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={props.faIcon}
            className="text-lg text-gray-800 mx-2 my-1"
          />
        </button>
      </Tippy>
    </div>
  );
};

export default CircularButton;
