// authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

// map
export const CHANGE_MAP_PARAMS = "CHANGE_MAP_PARAMS";

export const GETTING_FLOOD_GEOJSON_LIST = "GETTING_FLOOD_GEOJSON_LIST";
export const STORE_FLOOD_GEOJSON_LIST = "STORE_FLOOD_GEOJSON_LIST";

export const GETTING_FLOOD_LAYER_TITLES = "GETTING_FLOOD_LAYER_TITLES";
export const STORE_FLOOD_LAYER_TITLES = "STORE_FLOOD_LAYER_TITLES";

export const GETTING_FLOOD_FEATURE_COLLECTION =
  "GETTING_FLOOD_FEATURE_COLLECTION";
export const STORE_FLOOD_FEATURE_COLLECTION = "STORE_FLOOD_FEATURE_COLLECTION";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// Drawer
export const SHOW_DRAWER_LOADER = "SHOW_DRAWER_LOADER";
export const HIDE_DRAWER_LOADER = "HIDE_DRAWER_LOADER";

//satellite internet availability
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";

export const GETTING_LEO_DATA = "GETTING_LEO_DATA";
export const SET_LEO_DATA = "SET_LEO_DATA";

export const SET_SELECTED_POINT_TOGGLE = "SET_SELECTED_POINT_TOGGLE";
export const SET_POINT_SELECTED = "SET_POINT_SELECTED";

export const SET_GEOLOCATION_BUTTON_TOGGLE = "SET_GEOLOCATION_BUTTON_TOGGLE";
export const SET_PLOTTED_GEOLOCATION = "SET_PLOTTED_GEOLOCATION";


// disaster report
export const SET_ODK_PROJECT = "SET_ODK_PROJECT";

export const GETTING_DISASTER_POINTS = "GETTING_DISASTER_POINTS";
export const SET_DISASTER_POINTS = "SET_DISASTER_POINTS";

export const GETTING_ODK_DRAWER_DATA = "GETTING_ODK_DRAWER_DATA";
export const SET_ODK_DRAWER_DATA = "SET_ODK_DRAWER_DATA";

export const CLEAN_ODK_DRAWER = "CLEAN_ODK_DRAWER";

export const SHOW_PICTURE_MODAL = "SHOW_PICTURE_MODAL";
export const HIDE_PICTURE_MODAL = "HIDE_PICTURE_MODAL";