import React, { Fragment } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ProjectLoader = (props) => {
  return (
    <Fragment>
      {props.hasLoader && (
        <div className="w-full h-full fixed block top-0 left-0 bg-black opacity-50 z-1700 select-none">
          <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              size="5x"
              className="text-green-500"
            />
            {/* <i className="fas fa-circle-notch fa-spin fa-5x"></i> */}
          </span>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  hasLoader: state.mapReducer.hasLoader,
});

export default connect(mapStateToProps, {})(ProjectLoader);
